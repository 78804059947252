<template>
  <q-page class="ub-page">
    <div class="bvm_border rev_graf">
      <div class="row">
        <div class="col-4">
          <div class="page_faq">
            <label class="reit_name">Рейтинг</label>
            <div
              class="row"
              style="padding: 15px 0px;border-right: 1px solid; cursor: pointer"
              v-for="(rm, ind) in rateManagers"
              :key="'r' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/telfin/info/calls/${rm.user_id}`
                )
              "
            >
              <div class="col-3">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-4 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-3 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.progress + dayTitle(parseInt(rm.progress)) }}
                </div>
                <!--
                <q-linear-progress
                  size="20px"
                  :value="rm.progress"
                  :color="rm.color"
                  style="border-radius: 25px"
                >
                  <div
                    class="absolute-full flex flex-center"
                    style="border-radius: 25px"
                  >
                    <q-badge
                      color="white"
                      :text-color="rm.color"
                      :label="(rm.progress * 100).toFixed(0) + '%'"
                      style="border-radius: 25px"
                    />
                  </div>
                </q-linear-progress>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="page_faq">
            <label class="reit_name">Конверсия</label>
            <div
              class="row"
              style="padding: 15px 0px;border-right: 1px solid; cursor: pointer"
              v-for="(rm, ind) in convManagers"
              :key="'c' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/telfin/info/calls/${rm.user_id}`
                )
              "
            >
              <div class="col-2">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-2 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-2 analyt-items">{{ rm.f }}</div>
              <div class="col-4 analyt-items">
                <div
                  :style="
                    'text-align: center;width: 100%; color:' + rm.color
                  "
                ></div>
                <q-linear-progress
                  size="20px"
                  :value="rm.progress"
                  :color="rm.color"
                  style="border-radius: 25px"
                >
                  <div
                    class="absolute-full flex flex-center"
                    style="border-radius: 25px"
                  >
                    <q-badge
                      color="white"
                      :text-color="rm.color"
                      :label="(rm.progress * 100).toFixed(0) + '%'"
                      style="border-radius: 25px"
                    />
                  </div>
                </q-linear-progress>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="page_faq">
            <label class="reit_name">Пройденно тестов</label>
            <div
              class="row"
              style="padding: 15px 0px; cursor: pointer"
              v-for="(rm, ind) in trainManagers"
              :key="'t' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/telfin/info/tests/${rm.user_id}`
                )
              "
            >
              <div class="col-3">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-5 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-3 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.trains.answ + "/" + rm.trains.all }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="col-3">
          <div class="page_faq" style="min-height: 100%;display: grid">
            <div style="background-color: #a9d8ea" class="solvet_block">
              <div><label>Со временем работы с нашей компанией</label></div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <div class="bvm_border rev_graf">
      <div class="row">
        <div class="col-6">
          <div class="page_faq">
            <label class="reit_name">Колл-во звонков с 10 из 10 баллов</label>
            <div
              class="row"
              style="padding: 15px 0px;border-bottom: 1px solid; cursor: pointer"
              v-for="(rm, ind) in champManagers"
              :key="'r' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/telfin/info/calls/${rm.user_id}`
                )
              "
            >
              <div class="col-2">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-4 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-5 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.progress + callTitle(parseInt(rm.progress)) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="page_faq">
            <label class="reit_name">Рекомендации</label>
            <div
              class="row"
              style="padding: 15px 0;border-bottom: 1px solid; cursor: pointer"
              v-for="(rm, ind) in recsManagers"
              :key="'r' + rm.user_id + ind"
            >
              <div class="col-2">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-4 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-5 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.recs }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="col-3">
          <div class="page_faq" style="min-height: 100%;display: grid">
            <div style="background-color: #a9d8ea" class="solvet_block">
              <div><label>Идеальный кандидат</label></div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "telfin",
  data() {
    return {
      stages: [],
      stage: {},
      status: null,
      setMonth: 0,
      rateManagers: [],
      convManagers: [],
      trainManagers: [],
      champManagers: [],
      recsManagers: [],
      loc: "ru",
      index: 0
    };
  },
  computed: {},
  created() {
    this.setMonth = this.$store.state.setMonth;
  },
  mounted() {
    this.init();
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    },
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    }
  },
  methods: {
    init() {
      this.getRateMans();
      this.getConvMans();
      this.getTrainingMans();
      this.getChampMans();
      this.getRecsMans();
    },
    getRateMans() {
      this.index++;
      this.$h
        .get(`/analitics/telfin/get-man-rating?m=${this.setMonth}&y=null`)
        .then(r => {
          this.rateManagers = r.data.data;
          this.index--;
        });
    },
    getChampMans() {
      this.index++;
      this.$h
        .get(`/analitics/telfin/get-man-champ?m=${this.setMonth}&y=null`)
        .then(r => {
          this.champManagers = r.data.data;
          this.index--;
        });
    },
    getRecsMans() {
      this.index++;
      this.$h
        .get(`/analitics/telfin/get-man-recs?m=${this.setMonth}&y=null`)
        .then(r => {
          this.recsManagers = r.data.data;
          this.index--;
        });
    },
    getTrainingMans() {
      this.index++;
      this.$h
        .get(`/analitics/telfin/get-man-training?m=${this.setMonth}&y=null`)
        .then(r => {
          this.trainManagers = r.data.data;
          this.index--;
        });
    },
    getConvMans() {
      this.index++;
      this.$h
        .get(`/analitics/telfin/get-man-conv?m=${this.setMonth}&y=null`)
        .then(r => {
          this.convManagers = r.data.data;
          this.index--;
        });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (Math.abs(last_num) == 1) return " балл";
      if ([2, 3, 4].includes(Math.abs(last_num))) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(Math.abs(last_num))) return " баллов";
    },
    callTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " звонков";
      let last_num = number % 10;
      if (Math.abs(last_num) == 1) return " звонок";
      if ([2, 3, 4].includes(Math.abs(last_num))) return " звонка";
      if ([5, 6, 7, 8, 9, 0].includes(Math.abs(last_num))) return " звонков";
    }
  }
};
</script>

<style scoped>
.reit_name {
  color: #2d5ea9;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0;
}
.page_faq {
  padding: 30px;
}
.page_faq b,
.page_faq p {
  font-size: 16px;
}
.q-linear-progress__model,
.q-linear-progress__track {
  border-radius: 25px !important;
}

.analyt-items {
  display: flex;
  /* justify-content: center; */
  align-content: center;
  flex-wrap: wrap;
}
.solvet_block {
  color: #2d5ea9;
  padding: 15px;
  height: 100%;
  border-radius: 25px;
  background-color: rgb(169, 216, 234);
}
.solvet_block label {
  font-weight: 600;
}
.page_faq .row:hover {
  background-color: #fff9ea;
  cursor: pointer;
}
</style>
