<template>
  <v-chart
    style="text-align: left"
    class="chart"
    :option="option"
    :autoresize="true"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import { ToolboxComponent } from "echarts/components";
import VChart from "vue-echarts";
VChart.autoresize = true;

use([
  GridComponent,
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent
]);

export default {
  name: "LineChart",
  components: {
    VChart
  },
  props: {
    chOptions: {
      type: Object
    },
    reload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      option: {}
    };
  },
  watch: {
    reload(val) {}
  },
  mounted() {
    this.option = {
      color: this.chOptions.color,
      tooltip: this.chOptions.tooltip || {
        trigger: "axis",
        axisPointer: {
          animation: false
        }
      },
      grid: this.chOptions.grid || null,
      title: this.chOptions.title,
      legend: this.chOptions.legend || [],
      xAxis: this.chOptions.xAxis,
      yAxis: this.chOptions.yAxis,
      series: this.chOptions.series,
      toolbox: this.chOptions.toolbox || {}
    };
  },
  methods: {
    handleClick(...args) {}
  }
};
</script>

<style scoped></style>

<style>
body {
  margin: 0;
}
</style>
