<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-btn color="primary" @click="getNewCats" label="Получить стадии" />
      <q-card style="padding: 15px">
        <q-tabs v-model="stage" class="text-teal">
          <q-tab
            v-for="r in stages"
            :name="r.id"
            :label="r.name"
            :key="r.id"
            dense
          />
        </q-tabs>
        <q-tab-panels v-model="stage" :draggable="false">
          <q-tab-panel
            v-for="d in stages"
            :name="d.id"
            :label="d.name"
            :key="d.id + Math.random()"
          >
          </q-tab-panel>
        </q-tab-panels>
        <q-splitter
          v-model="fileL"
          style="min-height: 500px; overflow-y:scroll;align-items: start;text-align: left"
        >
          <template v-slot:before>
            <q-tabs
              v-model="status"
              vertical
              transition-prev="jump-up"
              transition-next="jump-up"
              animated
              class="panels_info"
            >
              <q-tab
                v-for="r in statuses"
                :name="r.stage_id + '_' + r.pipeline_id"
                :style="'background-color:' + r.color"
                :label="r.name"
                :key="r.stage_id + Math.random()"
              />
            </q-tabs>
          </template>
          <template v-slot:after>
            <q-tab-panels v-model="status">
              <q-tab-panel
                v-for="r in statuses"
                :name="r.stage_id + '_' + r.pipeline_id"
                :key="r.stage_id + Math.random()"
                style="min-height:500px;"
              >
                <q-btn
                  :style="
                    'float: left;color:#0f2027;background-color: ' + r.color
                  "
                  label="Звонок в чеклист"
                  @click="makeScript(r)"
                />
                <q-btn
                  style="float: right"
                  :label="'Сохранить для ' + r.name"
                  @click="setChecklist(r)"
                  color="primary"
                />
                <q-input
                  type="textarea"
                  style="width: 100%"
                  rows="20"
                  v-model.lazy="r.check_list"
                  :label="'Чеклист для ' + r.name"
                  autofocus
                />
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "amostages",
  components: {},
  data() {
    return {
      fileL: 20,
      stages: [],
      stage: 0,
      statuses: [],
      status: 0
    };
  },
  created() {},
  mounted() {
    this.getCats();
  },
  watch: {
    stage(v) {
      console.log(v);
      for (let i in this.stages) {
        if (this.stages[i].id == v) {
          this.statuses = this.stages[i].status;
          this.status =
            this.statuses[0].stage_id + "_" + this.statuses[0].pipeline_id;
        }
      }
    }
  },
  methods: {
    getCats() {
      this.$store.state.progress = true;
      this.$h.get("/crm/amo/stages").then(r => {
        this.stages = r.data.data.stages;
        if (this.stages.length > 0) {
          this.stage = this.stages[0].id;
        }
        this.$store.state.progress = false;
      });
    },
    getNewCats() {
      this.$store.state.progress = true;
      this.$h
        .get("/crm/amo/new-stages")
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            this.stages = r.data.data.stages;
            if (this.stages.length > 0) {
              this.stage = this.stages[0].id;
            }
          }
          this.$store.state.progress = false;
        })
        .catch(() => {
          this.$store.state.progress = false;
        });
    },
    setChecklist(ch) {
      this.$store.state.progress = true;
      this.$h.post("/crm/amo/set-stage", { st: ch }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
        }
        this.$store.state.progress = false;
      });
    },
    makeScript(st) {
      this.$store.state.progress = true;
      this.$h.post(`/groups/make-script`, { text: st.check_list }).then(r => {
        st.check_list = r.data.data;
        this.$store.state.progress = false;
      });
    },
    setCats() {}
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
