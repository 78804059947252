<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <div
          class="row"
          style="min-height: 75px;"
          v-if="!$store.state.progress && managerInfo"
        >
          <div class="col-1 man_info">
            <q-avatar
              :color="$colors[this.color]"
              size="75px"
              v-if="managerInfo.user_photo"
            >
              <img
                :alt="managerInfo.first_name"
                :src="$h.defaults.baseURL + managerInfo.user_photo"
              />
            </q-avatar>
            <q-avatar :color="$colors[color]" size="75px" v-else>
              <b style="font-size: 12px">{{
                managerInfo.first_name
                  ? managerInfo.first_name.substr(0, 2)
                  : ""
              }}</b>
            </q-avatar>
            <br />
            <div :class="'text-' + $colors[color]">
              {{ managerInfo.first_name }}
            </div>
          </div>
          <div class="col-2 man_info">
            <label class="reit_name">Рейтинг</label>
            <div :class="'text-' + $colors[color]">
              {{
                managerInfo.progress + dayTitle(parseInt(managerInfo.progress))
              }}
            </div>
          </div>
          <div
            class="col-2 man_info"
            style="text-align: center;cursor: pointer"
            @click="
              $router.push(
                `/analytics/manager-calls?id=${managerId}&ind=${color}`
              )
            "
          >
            <label class="reit_name">Конверсия</label>
            <div
              :style="'text-align: center;width: 100%; color:' + $colors[color]"
            >
              {{ managerInfo.f }} звонков
            </div>

            <q-linear-progress
              size="20px"
              :value="managerInfo.conv"
              :color="$colors[color]"
              style="border-radius: 25px"
            >
              <div
                class="absolute-full flex flex-center"
                style="border-radius: 25px"
              >
                <q-badge
                  color="white"
                  :text-color="$colors[color]"
                  :label="(managerInfo.conv * 100).toFixed(0) + '%'"
                  style="border-radius: 25px"
                />
              </div>
            </q-linear-progress>
          </div>
          <div
            style="cursor: pointer"
            class="col-2 man_info"
            @click="
              $router.push(
                `/analytics/manager-tests?id=${managerId}&ind=${color}`
              )
            "
          >
            <label class="reit_name">Пройденно тестов</label>
            <div :class="'text-' + $colors[color]">
              {{ managerInfo.trains.answ + "/" + managerInfo.trains.all }}
            </div>
          </div>
        </div>
      </q-card>
      <q-card style="padding: 15px">
        <q-tabs v-model="typeCall">
          <q-tab
            v-for="t in typesCall"
            :name="t"
            style="min-width: 100px"
            :label="$t('typeCalls')[t]"
            :key="t + Math.random()"
          />
        </q-tabs>
      </q-card>
      <q-card
        style="padding: 15px"
        v-if="!$store.state.progress && managerInfo"
      >
        <div>
          <q-radio
            v-model="topCalls"
            val="all"
            :label="`Все (${mcals.length})`"
          />
          <q-radio
            v-model="topCalls"
            val="top"
            :label="`Топ (${tcalls.length})`"
          />
          <q-radio
            v-model="topCalls"
            val="conv"
            :label="`С конверсией (${ccalls.length})`"
          />
          <q-radio
            v-model="topCalls"
            val="nconv"
            :label="`Без конверсии (${ncalls.length})`"
          />
        </div>
        <q-splitter
          v-model="fileL"
          style="height: 500px; overflow-y:scroll;align-items: start;text-align: left"
        >
          <template v-slot:before>
            <q-tabs
              v-model="file"
              v-if="topCalls == 'all'"
              vertical
              :class="'panels_info text-' + $colors[color]"
            >
              <q-tab
                v-for="(r, index) in mcals"
                :name="index"
                style="min-width: 100px;text-align: left"
                :label="r.client"
                :key="r.file_id"
                :hidden="r.flow !== typeCall"
              />
            </q-tabs>
            <q-tabs
              v-model="file"
              v-else-if="topCalls == 'top'"
              vertical
              :class="'panels_info text-' + $colors[color]"
            >
              <q-tab
                v-for="(r, index) in tcalls"
                :name="index"
                style="min-width: 100px"
                :label="r.client"
                :key="r.file_id"
                :hidden="r.flow !== typeCall"
              />
            </q-tabs>
            <q-tabs
              v-model="file"
              v-else-if="topCalls == 'conv'"
              vertical
              :class="'panels_info text-' + $colors[color]"
            >
              <q-tab
                v-for="(r, index) in ccalls"
                :name="index"
                style="min-width: 100px"
                :label="r.client"
                :key="r.file_id"
                :hidden="r.flow !== typeCall"
              />
            </q-tabs>
            <q-tabs
              v-model="file"
              v-else-if="topCalls == 'nconv'"
              vertical
              :class="'panels_info text-' + $colors[color]"
            >
              <q-tab
                v-for="(r, index) in ncalls"
                :name="index"
                style="min-width: 100px"
                :label="r.client"
                :key="r.file_id"
                :hidden="r.flow !== typeCall"
              />
            </q-tabs>
          </template>

          <template v-slot:after>
            <div class="q-tab-panel">
              <b
                v-html="
                  calls.length && calls[file].date
                    ? 'Дата обработки звонка : ' + calls[file].date
                    : null
                "
              />
            </div>
            <q-tab-panels
              v-model="file"
              animated
              vertical
              transition-prev="jump-up"
              transition-next="jump-up"
              :draggable="false"
              v-if="calls.length"
            >
              <q-tab-panel
                v-for="(d, index) in calls"
                :name="index"
                :label="d.file_name"
                :key="d.file_id + Math.random()"
                :hidden="d.flow !== typeCall"
              >
                <q-card>
                  <q-tabs
                    v-model="tab"
                    dense
                    class="text-grey"
                    active-color="primary"
                    indicator-color="primary"
                    align="justify"
                    narrow-indicator
                  >
                    <q-tab name="segments" label="Полный диалог" />
                    <q-tab name="transcript" label="Транскрипция" />
                    <q-tab name="call_script" label="Фактический скрипт" />
                    <q-tab name="recommendation" label="Соответствие скрипту" />
                  </q-tabs>

                  <q-separator />

                  <q-tab-panels
                    v-model="tab"
                    animated
                    transition-prev="scale"
                    transition-next="scale"
                  >
                    <q-tab-panel name="segments">
                      <div
                        v-if="calls[file].segments"
                        v-html="
                          calls[file].segments
                            .replaceAll('\n', '<br/>')
                            .replaceAll('. ', '.<br/>')
                            .replaceAll('? ', '?<br/>')
                            .replaceAll('! ', '!<br/>')
                        "
                      />
                    </q-tab-panel>
                    <q-tab-panel name="transcript">
                      <div
                        v-html="
                          calls[file].transcript.replaceAll('\n', '<br/>')
                        "
                      />
                    </q-tab-panel>

                    <q-tab-panel name="call_script">
                      <div
                        v-html="
                          calls[file].call_script.replaceAll('\n', '<br/>')
                        "
                      />
                    </q-tab-panel>

                    <q-tab-panel name="recommendation">
                      <table style="width: 100%;border-collapse: collapse;">
                        <tr>
                          <th>Категория</th>
                          <th>Текст</th>
                          <th>Баллы</th>
                        </tr>
                        <tr
                          style="border: 1px solid"
                          v-for="(rec, i) in calls[file].recommendation"
                          :key="i + Math.random() + '_rec'"
                        >
                          <td class="col-3" style=";padding: 5px">
                            {{ rec.ername }}
                          </td>
                          <td class="col-8" style=";padding: 5px">
                            {{ rec.error_text }}
                          </td>
                          <td
                            class="col-1"
                            style="text-align: right;padding: 5px"
                          >
                            {{ rec.points }}
                          </td>
                        </tr>
                      </table>
                    </q-tab-panel>
                  </q-tab-panels>
                </q-card>
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "manager-calls",
  props: {
    subTitle: null
  },
  data() {
    return {
      typeCall: "in",
      typesCall: [],
      setMouth: new Date().getMonth() + 1,
      topCalls: "all",
      layout: "dense",
      side: "right",
      fileL: 10,
      index: 0,
      messages: [],
      managerId: 0,
      color: 0,
      managerInfo: null,
      calls: [],
      mcals: [],
      tcalls: [],
      ccalls: [],
      ncalls: [],
      file: 0,
      tab: "segments"
    };
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    },
    typeCall() {
      this.getManagerCalls();
    },
    topCalls(val) {
      this.calls = [];
      switch (val) {
        case "conv":
          this.calls = this.ccalls;
          break;
        case "all":
          this.calls = this.mcals;
          break;
        case "top":
          this.calls = this.tcalls;
          break;
        case "nconv":
          this.calls = this.ncalls;
          break;
      }
      this.file = 0;
    },
    file(val) {
      this.selectFile = this.calls[val];
    }
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
    if (this.$route.query.id) {
      this.managerId = this.$route.query.id;
      this.color = this.$route.query.ind || 0;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getManagerInfo();
      this.getManagerCalls();
    },
    getManagerInfo() {
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/manager-info?id=${this.managerId}&m=${this.setMonth}`)
        .then(r => {
          this.managerInfo = r.data.data.m;
          this.$store.state.progress = false;
        });
    },
    getManagerCalls() {
      this.$store.state.progress = true;
      this.calls = [];
      this.tcalls = [];
      this.ccalls = [];
      this.$h
        .get(
          `/analitics/manager-calls?id=${this.managerId}&m=${this.setMonth}&type=${this.typeCall}`
        )
        .then(r => {
          this.mcals = r.data.data.calls;
          this.typesCall = r.data.data.types;
          for (let i in this.mcals) {
            let c = this.mcals[i];
            if (c.top == 1) {
              this.tcalls.push(c);
            }
            if (c.conv == 1) {
              this.ccalls.push(c);
            } else {
              this.ncalls.push(c);
            }
            this.calls.push(c);
          }
          this.$store.state.progress = false;
        });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (last_num == 1) return " балл";
      if ([2, 3, 4].includes(last_num)) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(last_num)) return " баллов";
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.man_info {
  align-items: center;
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
}

.panels_info .q-tabs__content {
  overflow-y: scroll;
}
</style>
