<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <div class="row align-items-center">
          <div class="col-2" v-for="(s, i) in disks" :key="i + Math.random()">
            <q-select
              v-model="disk[i]"
              :options="s"
              option-label="name"
              option-value="id"
              size="sm"
              label="Выбрать папку"
              @input="selectDisk(i)"
            />
          </div>
        </div>
      </q-card>
      <q-card style="padding: 5px;height: 100%">
        <div class="q-pa-lg">
          <h6 style="text-align: center">
            Конверсия менеджеров за {{ $months[setMonth - 1] }}
          </h6>
          <LineChart :ch-options="optionsCon" style="height: 350px" />
        </div>
      </q-card>
      <q-card style="padding: 5px;height: 100%">
        <h6 style="text-align: center">
          Динамика соблюдения скрипта менеджерами за {{ $months[setMonth - 1] }}
        </h6>
        <div class="q-pa-lg">
          <LineChart :ch-options="optionsSc" style="height: 350px" />
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import LineChart from "@/chart/LineChart.vue";

export default {
  name: "yad",
  components: { LineChart },
  props: {
    subTitle: null
  },
  data() {
    return {
      setMonth: new Date().getMonth(),
      setType: null,
      disks: [],
      disk: [],
      dindex: 0,
      optionsCon: {
        series: [],
        color: ["#0889B2"],
        grid: {
          top: "20",
          bottom: "35",
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: "100%",
          left: "center",
          //type: 'scroll',
          data: [],
          overflow: "break"
        },
        tooltip: {
          trigger: "axis",
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], "10%"];
          },
          renderMode: "html",
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          step: 10,
          min: 0,
          max: 100,
          type: "value",
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      optionsSc: {
        series: [],
        color: ["#0889B2"],
        grid: {
          top: "20",
          bottom: "35",
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: "100%",
          left: "center",
          //type: 'scroll',
          data: [],
          overflow: "break"
        },
        tooltip: {
          trigger: "axis",
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], "10%"];
          },
          renderMode: "html",
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          step: 10,
          min: 100,
          type: "value",
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      layout: "dense",
      side: "right",
      index: 0,
      messages: []
    };
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    },
    disk(val, old) {
      if (old.length == 0) {
        this.init();
      }
    },
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
    this.optionsCon.color = this.$gfcolors;
    this.optionsSc.color = this.$gfcolors;
    this.getCats(0);
  },
  mounted() {
    //this.init();
  },
  methods: {
    init() {
      this.optionsCon.xAxis.data.splice(0, this.optionsCon.xAxis.data.length);
      this.optionsSc.xAxis.data.splice(0, this.optionsSc.xAxis.data.length);
      this.optionsCon.series.splice(0, this.optionsCon.series.length);
      this.optionsSc.series.splice(0, this.optionsSc.series.length);
      this.optionsCon.legend.data.splice(0, this.optionsCon.legend.data.length);
      this.optionsSc.legend.data.splice(0, this.optionsSc.legend.data.length);
      this.getConvMans();
      this.getDnamicsMans();
    },
    selectDisk(i) {
      this.disk.splice(i + 1);
      let ind = [];
      for (let i in this.disk) {
        ind.push(this.disk[i].id);
      }
      this.setType = ind.join("_");
      this.getSubCats(i);
    },
    getConvMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/yandex/get-conv-mans?m=${this.setMonth}&t=${this.setType}`
        )
        .then(r => {
          let d = r.data.data.d;
          for (let i = 0; i < d.length; i++) {
            this.optionsCon.xAxis.data.push(d[i]);
          }

          let dt = r.data.data.m;
          for (let d in dt) {
            this.optionsCon.legend.data.push(dt[d].title);
            this.optionsCon.series.push({
              name: dt[d].title,
              type: "line",
              data: dt[d].data,
              color: this.$gfc[dt[d].color]
            });
          }
          this.index--;
        });
    },
    getDnamicsMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/yandex/get-din-mans?m=${this.setMonth}&t=${this.setType}`
        )
        .then(r => {
          let d = r.data.data.d;
          for (let i = 0; i < d.length; i++) {
            this.optionsSc.xAxis.data.push(d[i]);
          }

          let dt = r.data.data.m;
          for (let d in dt) {
            this.optionsSc.legend.data.push(dt[d].title);
            this.optionsSc.series.push({
              name: dt[d].title,
              type: "line",
              data: dt[d].data,
              color: this.$gfc[dt[d].color]
            });
          }
          this.index--;
        });
    },
    getCats(index) {
      this.disks = [];
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/yandex/stages?mindex=${index}`)
        .then(r => {
          this.disks.push(r.data.data.stages);
          this.disk.push(r.data.data.stages[0]);
          this.$store.state.progress = false;
        })
        .then(() => {
          this.selectDisk(0);
        });
    },
    getSubCats(index) {
      this.disks.splice(1, this.disks.length);
      //this.disk.splice(index +1 , this.disk.length);
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/yandex/sub-stages?mindex=${this.setType}`)
        .then(r => {
          let st = r.data.data.stages;
          for (let i = 0; i < st.length; i++) {
            if (st[i].length) {
              this.disks.push(st[i]);
            }
          }
          this.$store.state.progress = false;
        })
        .then(() => {
          this.init();
        });
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}
</style>
