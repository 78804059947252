<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <div class="row">
          <div class="col-2">
            <q-select
              filled
              size="xs"
              v-model="stage"
              option-value="id"
              option-label="name"
              :options="stages"
              label="Категория"
            />
          </div>
          <div class="col-10">
            <q-tabs v-model="status" v-if="stage !== null">
              <q-tab
                v-for="r in stage.status"
                :name="r.status_id + '_' + r.cat_id"
                :label="r.name"
                :style="'background-color:' + r.color"
                :key="r.status_id + Math.random()"
                dense
                ><q-badge :label="r.cfile" color="red" transparent
              /></q-tab>
            </q-tabs>
          </div>
        </div>
      </q-card>
      <q-card style="padding: 5px;height: 100%">
        <div class="q-pa-lg">
          <h6 style="text-align: center">
            Конверсия менеджеров за {{ $months[setMonth - 1] }}
          </h6>
          <LineChart :ch-options="optionsCon" style="height: 350px" />
        </div>
      </q-card>
      <q-card style="padding: 5px;height: 100%">
        <h6 style="text-align: center">
          Динамика соблюдения скрипта менеджерами за {{ $months[setMonth - 1] }}
        </h6>
        <div class="q-pa-lg">
          <LineChart :ch-options="optionsSc" style="height: 350px" />
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import LineChart from "@/chart/LineChart.vue";

export default {
  name: "bi24_crm",
  components: { LineChart },
  props: {
    subTitle: null
  },
  data() {
    return {
      setMonth: new Date().getMonth(),
      setType: null,
      optionsCon: {
        series: [],
        color: ["#0889B2"],
        grid: {
          top: "20",
          bottom: "35",
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: "100%",
          left: "center",
          //type: 'scroll',
          data: [],
          overflow: "break"
        },
        tooltip: {
          trigger: "axis",
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], "10%"];
          },
          renderMode: "html",
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          step: 1,
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          step: 10,
          min: 0,
          max: 100,
          type: "value",
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      optionsSc: {
        series: [],
        color: ["#0889B2"],
        grid: {
          top: "20",
          bottom: "35",
          containLabel: false
        },
        legend: {
          padding: [0, 0, 0, 0],
          width: "100%",
          left: "center",
          //type: 'scroll',
          data: [],
          overflow: "break"
        },
        tooltip: {
          trigger: "axis",
          position: function(point, params, dom, rect, size) {
            // fixed at top
            return [point[0], "10%"];
          },
          renderMode: "html",
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          splitLine: {
            show: true
          },
          axisLabel: {
            interval: 0
          },
          data: []
        },
        yAxis: {
          step: 10,
          type: "value",
          splitLine: {
            show: true
          }
        },
        title: {}
      },
      layout: "dense",
      side: "right",
      index: 0,
      messages: [],
      stages: [],
      stage: {},
      status: null
    };
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.subInit();
    },
    "$store.state.setType"(val) {
      this.setType = val;
      this.subInit();
    },
    stage(val) {
      if(val.status.length) {
        this.status = val.status[0].status_id + "_" + val.status[0].cat_id;
      }else {
        this.status=null;
      }
    },
    status(val) {
      this.subInit();
    },
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
    this.setType = this.$store.state.setType;
    this.optionsCon.color = this.$gfcolors;
    this.optionsSc.color = this.$gfcolors;
  },
  mounted() {
    if (this.setType) {
      this.init();
    }
  },
  methods: {
    subInit() {
      this.optionsCon.xAxis.data.splice(0, this.optionsCon.xAxis.data.length);
      this.optionsSc.xAxis.data.splice(0, this.optionsSc.xAxis.data.length);
      this.optionsCon.series.splice(0, this.optionsCon.series.length);
      this.optionsSc.series.splice(0, this.optionsSc.series.length);
      this.getConvMans();
      this.getDnamicsMans();
    },
    init() {
      this.optionsCon.xAxis.data.splice(0, this.optionsCon.xAxis.data.length);
      this.optionsSc.xAxis.data.splice(0, this.optionsSc.xAxis.data.length);
      this.optionsCon.series.splice(0, this.optionsCon.series.length);
      this.optionsSc.series.splice(0, this.optionsSc.series.length);
      this.getCats();
    },
    getConvMans() {
      this.optionsCon.legend.data.splice(0, this.optionsCon.legend.data.length);
      this.index++;
      this.$h
        .get(
          `/analitics/${this.setType.replace("_", "-")}/get-conv-mans?m=${
            this.setMonth
          }&s=${this.status}`
        )
        .then(r => {
          let d = r.data.data.d;
          for (let i = 0; i < d.length; i++) {
            this.optionsCon.xAxis.data.push(d[i]);
          }
          let dt = r.data.data.m;
          for (let d in dt) {
            this.optionsCon.legend.data.push(dt[d].title);
            this.optionsCon.series.push({
              name: dt[d].title,
              type: "line",
              data: dt[d].data,
              color: this.$gfc[dt[d].color],
            });
          }
          this.index--;
        });
    },
    getDnamicsMans() {
      this.optionsSc.legend.data.splice(0, this.optionsSc.legend.data.length);
      this.index++;
      this.$h
        .get(
          `/analitics/${this.setType.replace("_", "-")}/get-din-mans?m=${
            this.setMonth
          }&s=${this.status}`
        )
        .then(r => {
          let d = r.data.data.d;
          for (let i = 0; i < d.length; i++) {
            this.optionsSc.xAxis.data.push(d[i]);
          }
          let dt = r.data.data.m;
          for (let d in dt) {
            this.optionsSc.legend.data.push(dt[d].title);
            this.optionsSc.series.push({
              name: dt[d].title,
              type: "line",
              data: dt[d].data,
              color: this.$gfc[dt[d].color]
            });
          }
          this.index--;
        });
    },
    getCats() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/analitics/${this.setType.replace("_", "-")}/stages?m=${
            this.setMonth
          }`
        )
        .then(r => {
          this.stages = r.data.data.stages;
          if (this.stages.length > 0) {
            this.stage = this.stages[0];
            if (this.stage.status[0]) {
              this.status =
                this.stage.status[0].status_id +
                "_" +
                this.stage.status[0].cat_id;
            }
          }
          this.$store.state.progress = false;
        });
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}
</style>
