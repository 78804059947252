<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <div class="row">
          <div class="col-3">
            <q-input
              danse
              size="sm"
              label="Название тега"
              type="text"
              v-model="tag.name"
            />
          </div>
          <div class="col-6">
            <q-input label="Ключевая фраза" danse v-model="tag.keyword" />
          </div>
          <div class="col-3">
            <div class="q-pa-md q-gutter-sm">
              <q-btn
                color="blue"
                align="right"
                size="md"
                label="Добавить тэг"
                @click="addTag()"
              />
            </div>
          </div>
        </div>
        <div
          class="row"
          style="padding: 15px"
          v-for="(t, i) in tags"
          :key="i + Math.random()"
        >
          <div class="col-3">
            {{ t.name }}
          </div>
          <div class="col-6">
            {{ t.keyword }}
          </div>
          <div class="col-3">
            <div class="q-pa-md q-gutter-sm">
              <q-btn
                color="red"
                align="right"
                size="md"
                label="Удалить тэг"
                @click="delTag(i)"
              />
            </div>
          </div>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "teltags",
  components: {},
  data() {
    return {
      tag: { id: 0, name: "", keyword: "" },
      tags: [],
      tel: null
    };
  },
  created() {
    this.tel = this.$route.query.type;
  },
  mounted() {
    this.getTags();
  },
  watch: {},
  methods: {
    addTag() {
      const n = this.tag.name;
      if (n.length < 3) {
        Notify.create({
          position: "top",
          type: "errors",
          color: "yellow",
          message: "Короткое название тэга",
          timeout: 2000
        });
        return false;
      } else if (this.tag.keyword.length < 5) {
        Notify.create({
          position: "top",
          type: "errors",
          color: "yellow",
          message: "Минимум 5 символов в Ключевой фразе",
          timeout: 2000
        });
        return false;
      } else {
        for (let t in this.tags) {
          if (this.tags[t].name == n) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Тэг с таким именем уже есть!",
              timeout: 2000
            });
            return;
          }
        }
      }
      this.tags.push(this.tag);
      this.setTags();
      this.tag = { id: 0, name: "", keyword: "" };
    },
    delTag(i) {
      this.delTags(this.tags[i]);
      this.tags.splice(i, 1);
    },
    getTags() {
      this.$store.state.progress = true;
      this.$h.get(`/groups/get-tags?type=${this.tel}`).then(r => {
        this.show = false;
        this.tags = r.data.data;
        this.$store.state.progress = false;
      });
    },
    setTags() {
      this.$store.state.progress = true;
      this.$h
        .post(`/groups/set-tags?type=${this.tel}`, { t: this.tags })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.show = false;
          }
          this.$store.state.progress = false;
        });
    },
    delTags(tag) {
      this.$store.state.progress = true;
      this.$h.get(`/groups/del-tag?type=${this.tel}&name=${tag.name}&id=${tag.id}`).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.show = false;
        }
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
