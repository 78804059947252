<template>
  <div class="q-gutter-y-md" style="max-width: 100%;">
    <q-card style="padding: 15px">
      <label><h5>Настройки подключения АМОСрм</h5> </label>
    </q-card>
    <q-card style="padding: 15px">
      <q-tabs v-model="tab" class="text-teal">
        <q-tab
          v-for="r in tabs"
          :name="r.id"
          :label="r.name"
          :key="r.id"
          dense
        />
      </q-tabs>
    </q-card>
    <q-card style="padding: 15px">
      <q-tab-panels v-model="tab" :draggable="false">
        <q-tab-panel name="main" label="Общие настройки">
          <div class="row" style="padding: 15px">
            <div class="col-4">
              <a
                target="_blank"
                href="https://www.amocrm.ru/developers/content/oauth/step-by-step#Регистрация-приложения"
                >Как получить ключи вторицации (инструкция в ссыле)</a
              >
            </div>
            <div class="col-8">
              <q-btn
                style="margin-bottom: 5px;float: right"
                color="blue"
                @click="setInfo()"
                >Сохранить</q-btn
              >
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4">
              Урл сервера
            </div>
            <div class="col-8">
              <input
                v-model="settings.subdomain"
                type="text"
                placeholder="https://{domen}.amocrm.ru/"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4">
              ID интеграции
            </div>
            <div class="col-8">
              <input
                v-model="settings.client_id"
                type="text"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4">
              Секретный ключ
            </div>
            <div class="col-8">
              <input
                v-model="settings.client_secret"
                type="text"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4">
              Код Авторизации (<span style="color: red">при каждом сохранении нужно генерировать новый код!!!</span>)
            </div>
            <div class="col-8">
              <input v-model="settings.code" type="text" style="width: 100%" />
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4">
              Ваш урл редиректа для подключения интеграции
            </div>
            <div class="col-8">
              <div>
                <b>{{ settings.redirect_uri }}</b>
              </div>
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4">
              Минимальное время звонка от
            </div>
            <div class="col-8">
              <input
                type="number"
                v-model="settings.min_time"
                style="width: 100%"
              />
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <q-btn
              color="green"
              align="right"
              size="sm"
              label="Настройка Тегов"
              @click="$router.push('/crm/tags?type=amo_crm')"
              style="margin-bottom: 15px;"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel name="sub" label="Общие настройки">
          <Amostages />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-dialog v-model="makeCheck" persistent>
      <q-card class="column" style="width: 600px" v-if="checkL == null">
        <q-card-section>
          <div class="text-h6">Вставьте текст звонка</div>
        </q-card-section>

        <q-card-section class="col q-pt-none">
          <q-input v-if="checkL == null" type="textarea" v-model="etcall" />
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn color="red" label="Закрыть" v-close-popup />
          <q-btn color="blue" label="Преобразовать" @click="makeScript" />
        </q-card-actions>
      </q-card>
      <q-card class="column" style="width: 600px" v-else-if="checkL != null">
        <q-card-section>
          <div class="text-h6">Скопируйте чеклист</div>
        </q-card-section>

        <q-card-section class="col q-pt-none">
          <div>{{ checkL }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn color="blue" label="Закрыть" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Amostages from "@/components/crm-setting/stages-amo.vue";
import { Notify } from "quasar";

export default {
  name: "amocrm",
  components: { Amostages },
  data() {
    return {
      tabs: [
        { id: "main", name: "Общие настройки" },
        { id: "sub", name: "Стадии и скрипты" }
      ],
      tab: "main",
      settings: {
        redirect_uri: "https://gpt.bvmax.ru/",
        client_id: "",
        client_secret: "",
        min_time: 0,
        type_tel: null,
        subdomain: ""
      },
      etcall: "",
      checkL: null,
      checklists: {
        in: ""
      },
      makeCheck: false
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  watch: {
    makeCheck(val) {
      if (val == false) {
        this.checklists.in = this.checkL;
        this.checkL = null;
      }
    }
  },
  methods: {
    getInfo() {
      this.$store.state.progress = true;
      this.$h.get(`/groups/get-amocrm`).then(r => {
        this.settings = r.data.data.creds;
        this.checklists = r.data.data.checklists;
        this.$store.state.progress = false;
      });
    },
    setInfo() {
      /*      if(this.settings.type_tel==null){
                Notify.create({
                  position: "top",
                  type: "errors",
                  color: "red",
                  message: "Выберете телефонию!",
                  timeout: 2000
                });
              return
            }*/

      this.$store.state.progress = true;
      this.$h
        .post(`/groups/get-amocrm`, {
          credentials: this.settings,
          check: this.checklists
        })
        .then(r => {
          if (r.data.data != null) {
            this.settings = r.data.data.creds;
            this.checklists = r.data.data.checklists;
          }
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    },
    makeScript() {
      this.$store.state.progress = true;
      this.$h.post(`/groups/make-script`, { text: this.etcall }).then(r => {
        this.checkL = r.data.data;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
