var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"ub-page"},[_c('div',{staticClass:"bvm_border rev_graf"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"page_faq"},[_c('label',{staticClass:"reit_name"},[_vm._v("Рейтинг")]),_vm._l((_vm.rateManagers),function(rm,ind){return _c('div',{key:'r' + rm.user_id + ind,staticClass:"row",staticStyle:{"padding":"15px 0px","border-right":"1px solid","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(
                `/managers/telfin/info/calls/${rm.user_id}`
              )}}},[_c('div',{staticClass:"col-3"},[(rm.user_photo)?_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('img',{attrs:{"alt":rm.first_name,"src":_vm.$h.defaults.baseURL + rm.user_photo}})]):_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(rm.first_name.substr(0, 2)))])])],1),_c('div',{staticClass:"col-4 analyt-items"},[_c('div',{class:'text-' + rm.color,staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(rm.first_name.length > 8 ? rm.first_name.slice(0, 6) + "..." : rm.first_name)+" ")])]),_c('div',{staticClass:"col-3 analyt-items"},[_c('div',{class:'text-' + rm.color},[_vm._v(" "+_vm._s(rm.progress + _vm.dayTitle(parseInt(rm.progress)))+" ")])])])})],2)]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"page_faq"},[_c('label',{staticClass:"reit_name"},[_vm._v("Конверсия")]),_vm._l((_vm.convManagers),function(rm,ind){return _c('div',{key:'c' + rm.user_id + ind,staticClass:"row",staticStyle:{"padding":"15px 0px","border-right":"1px solid","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(
                `/managers/telfin/info/calls/${rm.user_id}`
              )}}},[_c('div',{staticClass:"col-2"},[(rm.user_photo)?_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('img',{attrs:{"alt":rm.first_name,"src":_vm.$h.defaults.baseURL + rm.user_photo}})]):_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(rm.first_name.substr(0, 2)))])])],1),_c('div',{staticClass:"col-2 analyt-items"},[_c('div',{class:'text-' + rm.color,staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(rm.first_name.length > 8 ? rm.first_name.slice(0, 6) + "..." : rm.first_name)+" ")])]),_c('div',{staticClass:"col-2 analyt-items"},[_vm._v(_vm._s(rm.f))]),_c('div',{staticClass:"col-4 analyt-items"},[_c('div',{style:('text-align: center;width: 100%; color:' + rm.color)}),_c('q-linear-progress',{staticStyle:{"border-radius":"25px"},attrs:{"size":"20px","value":rm.progress,"color":rm.color}},[_c('div',{staticClass:"absolute-full flex flex-center",staticStyle:{"border-radius":"25px"}},[_c('q-badge',{staticStyle:{"border-radius":"25px"},attrs:{"color":"white","text-color":rm.color,"label":(rm.progress * 100).toFixed(0) + '%'}})],1)])],1)])})],2)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"page_faq"},[_c('label',{staticClass:"reit_name"},[_vm._v("Пройденно тестов")]),_vm._l((_vm.trainManagers),function(rm,ind){return _c('div',{key:'t' + rm.user_id + ind,staticClass:"row",staticStyle:{"padding":"15px 0px","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(
                `/managers/telfin/info/tests/${rm.user_id}`
              )}}},[_c('div',{staticClass:"col-3"},[(rm.user_photo)?_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('img',{attrs:{"alt":rm.first_name,"src":_vm.$h.defaults.baseURL + rm.user_photo}})]):_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(rm.first_name.substr(0, 2)))])])],1),_c('div',{staticClass:"col-5 analyt-items"},[_c('div',{class:'text-' + rm.color,staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(rm.first_name.length > 8 ? rm.first_name.slice(0, 6) + "..." : rm.first_name)+" ")])]),_c('div',{staticClass:"col-3 analyt-items"},[_c('div',{class:'text-' + rm.color},[_vm._v(" "+_vm._s(rm.trains.answ + "/" + rm.trains.all)+" ")])])])})],2)])])]),_c('div',{staticClass:"bvm_border rev_graf"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"page_faq"},[_c('label',{staticClass:"reit_name"},[_vm._v("Колл-во звонков с 10 из 10 баллов")]),_vm._l((_vm.champManagers),function(rm,ind){return _c('div',{key:'r' + rm.user_id + ind,staticClass:"row",staticStyle:{"padding":"15px 0px","border-bottom":"1px solid","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(
                `/managers/telfin/info/calls/${rm.user_id}`
              )}}},[_c('div',{staticClass:"col-2"},[(rm.user_photo)?_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('img',{attrs:{"alt":rm.first_name,"src":_vm.$h.defaults.baseURL + rm.user_photo}})]):_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(rm.first_name.substr(0, 2)))])])],1),_c('div',{staticClass:"col-4 analyt-items"},[_c('div',{class:'text-' + rm.color,staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(rm.first_name.length > 8 ? rm.first_name.slice(0, 6) + "..." : rm.first_name)+" ")])]),_c('div',{staticClass:"col-5 analyt-items"},[_c('div',{class:'text-' + rm.color},[_vm._v(" "+_vm._s(rm.progress + _vm.callTitle(parseInt(rm.progress)))+" ")])])])})],2)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"page_faq"},[_c('label',{staticClass:"reit_name"},[_vm._v("Рекомендации")]),_vm._l((_vm.recsManagers),function(rm,ind){return _c('div',{key:'r' + rm.user_id + ind,staticClass:"row",staticStyle:{"padding":"15px 0","border-bottom":"1px solid","cursor":"pointer"}},[_c('div',{staticClass:"col-2"},[(rm.user_photo)?_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('img',{attrs:{"alt":rm.first_name,"src":_vm.$h.defaults.baseURL + rm.user_photo}})]):_c('q-avatar',{attrs:{"color":rm.color,"size":"35px"}},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(rm.first_name.substr(0, 2)))])])],1),_c('div',{staticClass:"col-4 analyt-items"},[_c('div',{class:'text-' + rm.color,staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(rm.first_name.length > 8 ? rm.first_name.slice(0, 6) + "..." : rm.first_name)+" ")])]),_c('div',{staticClass:"col-5 analyt-items"},[_c('div',{class:'text-' + rm.color},[_vm._v(" "+_vm._s(rm.recs)+" ")])])])})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }