<template>
  <div class="q-gutter-y-md" style="max-width: 100%;">
    <q-card style="padding: 15px" v-if="!$store.state.progress && managerInfo">
      <div>
        <q-radio
          v-model="topCalls"
          val="all"
          :label="`Все (${mcals.length})`"
        />
        <q-radio
          v-model="topCalls"
          val="top"
          :label="`Топ (${tcalls.length})`"
        />
        <q-radio
          v-model="topCalls"
          val="conv"
          :label="`С конверсией (${ccalls.length})`"
        />
        <q-radio
          v-model="topCalls"
          val="nconv"
          :label="`Без конверсии (${ncalls.length})`"
        />
        <q-radio
          v-model="topCalls"
          val="favorite"
          :label="`Избранные (${favorites.length})`"
        />
      </div>
      <q-splitter
        v-model="fileL"
        style="height: 500px; overflow-y:scroll;align-items: start;text-align: left"
      >
        <template v-slot:before>
          <q-tabs
            v-model="file"
            v-if="topCalls == 'all'"
            vertical
            :class="'panels_info text-' + color"
          >
            <q-tab
              v-for="(r, index) in mcals"
              :name="index"
              style="min-width: 100px;text-align: left"
              :label="r.client"
              :key="r.file_id"
              :hidden="r.flow !== typeCall"
            />
          </q-tabs>
          <q-tabs
            v-model="file"
            v-else-if="topCalls == 'top'"
            vertical
            :class="'panels_info text-' + color"
          >
            <q-tab
              v-for="(r, index) in tcalls"
              :name="index"
              style="min-width: 100px"
              :label="r.client"
              :key="r.file_id"
              :hidden="r.flow !== typeCall"
            />
          </q-tabs>
          <q-tabs
            v-model="file"
            v-else-if="topCalls == 'conv'"
            vertical
            :class="'panels_info text-' + color"
          >
            <q-tab
              v-for="(r, index) in ccalls"
              :name="index"
              style="min-width: 100px"
              :label="r.client"
              :key="r.file_id"
              :hidden="r.flow !== typeCall"
            />
          </q-tabs>
          <q-tabs
            v-model="file"
            v-else-if="topCalls == 'nconv'"
            vertical
            :class="'panels_info text-' + color"
          >
            <q-tab
              v-for="(r, index) in ncalls"
              :name="index"
              style="min-width: 100px"
              :label="r.client"
              :key="r.file_id"
              :hidden="r.flow !== typeCall"
            />
          </q-tabs>
          <q-tabs
            v-model="file"
            v-else-if="topCalls == 'favorite'"
            vertical
            :class="'panels_info text-' + color"
          >
            <q-tab
              v-for="(r, index) in favorites"
              :name="index"
              style="min-width: 100px"
              :label="r.client"
              :key="r.file_id"
              :hidden="r.flow !== typeCall"
            />
          </q-tabs>
        </template>

        <template v-slot:after>
          <div
            class="q-tab-panel"
            v-if="topCalls != 'favorite' && calls.length"
          >
            <b
              v-html="
                calls.length && calls[file].date
                  ? 'Дата обработки звонка : ' + calls[file].date
                  : null
              "
            />
            <q-icon
              name="favorite"
              size="24px"
              style="float: right;cursor: pointer"
              @click="setFav(file)"
              :color="fids.indexOf(calls[file].file_id) >= 0 ? 'red' : 'blue'"
              float="right"
            />
          </div>
          <div
            class="q-tab-panel"
            v-if="topCalls == 'favorite' && favorites.length"
          >
            <b
              v-html="
                favorites.length && favorites[file].date
                  ? 'Дата обработки звонка : ' + favorites[file].date
                  : null
              "
            />
            <q-icon
              v-if="fids.length"
              name="favorite"
              size="24px"
              style="float: right;cursor: pointer"
              @click="setFav(file)"
              :color="
                fids.indexOf(favorites[file].file_id) >= 0 ? 'red' : 'blue'
              "
              float="right"
            />
          </div>
          <q-tab-panels
            v-model="file"
            animated
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
            :draggable="false"
            v-if="calls.length && topCalls != 'favorite'"
          >
            <q-tab-panel
              v-for="(d, index) in calls"
              :name="index"
              :label="d.file_name"
              :key="d.file_id + Math.random()"
              :hidden="d.flow !== typeCall"
            >
              <q-card>
                <q-tabs
                  v-model="tab"
                  dense
                  class="text-grey"
                  active-color="primary"
                  indicator-color="primary"
                  align="justify"
                  narrow-indicator
                >
                  <q-tab name="segments" label="Полный диалог" />
                  <q-tab name="transcript" label="Транскрипция" />
                  <q-tab name="call_script" label="Фактический скрипт" />
                  <q-tab name="recommendation" label="Соответствие скрипту" />
                </q-tabs>

                <q-separator />

                <q-tab-panels
                  v-model="tab"
                  animated
                  transition-prev="scale"
                  transition-next="scale"
                >
                  <q-tab-panel name="segments">
                    <div
                      v-if="calls[file].segments"
                      v-html="
                        calls[file].segments
                          .replaceAll('\n', '<br/>')
                          .replaceAll('. ', '.<br/>')
                          .replaceAll('? ', '?<br/>')
                          .replaceAll('! ', '!<br/>')
                      "
                    />
                  </q-tab-panel>
                  <q-tab-panel name="transcript">
                    <div
                      v-html="calls[file].transcript.replaceAll('\n', '<br/>')"
                    />
                  </q-tab-panel>

                  <q-tab-panel name="call_script">
                    <div
                      v-html="calls[file].call_script.replaceAll('\n', '<br/>')"
                    />
                  </q-tab-panel>

                  <q-tab-panel name="recommendation">
                    <table style="width: 100%;border-collapse: collapse;">
                      <tr>
                        <th>Категория</th>
                        <th>Текст</th>
                        <th>Баллы</th>
                      </tr>
                      <tr
                        style="border: 1px solid"
                        v-for="(rec, i) in calls[file].recommendation"
                        :key="i + Math.random() + '_rec'"
                      >
                        <td
                          class="col-3"
                          style=";padding: 5px;max-width: 250px"
                        >
                          {{ rec.ername }}
                        </td>
                        <td class="col-8" style=";padding: 5px">
                          {{ rec.error_text }}
                        </td>
                        <td
                          class="col-1"
                          style="text-align: right;padding: 5px"
                        >
                          {{ rec.points }}
                        </td>
                      </tr>
                    </table>
                  </q-tab-panel>
                </q-tab-panels>
              </q-card>
            </q-tab-panel>
          </q-tab-panels>
          <q-tab-panels
            v-model="file"
            animated
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
            :draggable="false"
            v-if="favorites.length && topCalls == 'favorite'"
          >
            <q-tab-panel
              v-for="(d, index) in favorites"
              :name="index"
              :label="d.file_name"
              :key="d.file_id + Math.random()"
              :hidden="d.flow !== typeCall"
            >
              <q-card>
                <q-tabs
                  v-model="tab"
                  dense
                  class="text-grey"
                  active-color="primary"
                  indicator-color="primary"
                  align="justify"
                  narrow-indicator
                >
                  <q-tab name="segments" label="Полный диалог" />
                  <q-tab name="transcript" label="Транскрипция" />
                  <q-tab name="call_script" label="Фактический скрипт" />
                  <q-tab name="recommendation" label="Соответствие скрипту" />
                </q-tabs>

                <q-separator />

                <q-tab-panels
                  v-model="tab"
                  animated
                  transition-prev="scale"
                  transition-next="scale"
                >
                  <q-tab-panel name="segments">
                    <div
                      v-if="favorites[file].segments"
                      v-html="
                        favorites[file].segments
                          .replaceAll('\n', '<br/>')
                          .replaceAll('. ', '.<br/>')
                          .replaceAll('? ', '?<br/>')
                          .replaceAll('! ', '!<br/>')
                      "
                    />
                  </q-tab-panel>
                  <q-tab-panel name="transcript">
                    <div
                      v-html="
                        favorites[file].transcript.replaceAll('\n', '<br/>')
                      "
                    />
                  </q-tab-panel>

                  <q-tab-panel name="call_script">
                    <div
                      v-html="
                        favorites[file].call_script.replaceAll('\n', '<br/>')
                      "
                    />
                  </q-tab-panel>

                  <q-tab-panel name="recommendation">
                    <table style="width: 100%;border-collapse: collapse;">
                      <tr>
                        <th>Категория</th>
                        <th>Текст</th>
                        <th>Баллы</th>
                      </tr>
                      <tr
                        style="border: 1px solid"
                        v-for="(rec, i) in favorites[file].recommendation"
                        :key="i + Math.random() + '_rec'"
                      >
                        <td
                          class="col-3"
                          style=";padding: 5px;max-width: 250px"
                        >
                          {{ rec.ername }}
                        </td>
                        <td class="col-8" style=";padding: 5px">
                          {{ rec.error_text }}
                        </td>
                        <td
                          class="col-1"
                          style="text-align: right;padding: 5px"
                        >
                          {{ rec.points }}
                        </td>
                      </tr>
                    </table>
                  </q-tab-panel>
                </q-tab-panels>
              </q-card>
            </q-tab-panel>
          </q-tab-panels>
        </template>
      </q-splitter>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "amo-calls",
  props: {
    subTitle: null,
    color:{
      type:String
    },
    managerId: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      typeCall: "in",
      typesCall: [],
      setMouth: new Date().getMonth() + 1,
      topCalls: "all",
      layout: "dense",
      side: "right",
      fileL: 10,
      index: 0,
      messages: [],
      managerInfo: null,
      favorites: [],
      fids: [],
      calls: [],
      mcals: [],
      tcalls: [],
      ccalls: [],
      ncalls: [],
      file: 0,
      tab: "segments"
    };
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    },
    status() {
      this.init();
    },
    typeCall() {
      this.getManagerCalls();
    },
    topCalls(val) {
      this.calls = [];
      switch (val) {
        case "conv":
          this.calls = this.ccalls;
          break;
        case "all":
          this.calls = this.mcals;
          break;
        case "top":
          this.calls = this.tcalls;
          break;
        case "nconv":
          this.calls = this.ncalls;
          break;
      }
      this.file = 0;
    },
    file(val) {
      this.selectFile = this.calls[val];
    }
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
    if (this.$route.query.id) {
      this.managerId = this.$route.query.id;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getManagerInfo();
      this.getManagerCalls();
    },
    getManagerInfo() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/analitics/amo-crm/manager-info?id=${this.managerId}&m=${this.setMonth}&s=${this.status}`
        )
        .then(r => {
          this.managerInfo = r.data.data.m;
          this.$store.state.progress = false;
        });
    },
    setFav(id) {
      let fid = 0;
      if (this.topCalls != "favorites" && this.calls.length > 0) {
        fid = this.calls[id].file_id;
        if (this.fids.indexOf(fid) == -1) {
          this.fids.push(fid);
          this.favorites.push(this.calls[id]);
        } else {
          this.favorites.splice(this.favorites.indexOf(this.calls[id]), 1);
          this.fids.splice(this.fids.indexOf(fid), 1);
        }
      } else {
        fid = this.favorites[id].file_id;
        if (this.fids.indexOf(fid) == -1) {
          this.fids.push(fid);
          this.favorites.push(this.favorites[id]);
        } else {
          this.favorites.splice(id, 1);
          this.fids.splice(this.fids.indexOf(fid), 1);
        }
      }
      this.$h
        .get(`/analitics/amo-crm/set-fav?type=vfile&id=${fid}`)
        .then(r => {});
    },
    getManagerCalls() {
      this.$store.state.progress = true;
      this.topCalls = "all";
      this.calls = [];
      this.tcalls = [];
      this.ccalls = [];
      this.mcals = [];
      this.ncalls = [];
      this.favorites = [];
      (this.file = 0),
        this.$h
          .get(
            `/analitics/amo-crm/manager-calls?id=${this.managerId}&m=${this.setMonth}&s=${this.status}`
          )
          .then(r => {
            this.mcals = r.data.data.calls;
            this.favorites = r.data.data.favorites.files;
            this.fids = r.data.data.favorites.fids;
            this.typesCall = r.data.data.types;
            for (let i in this.mcals) {
              let c = this.mcals[i];
              if (c.top == 1) {
                this.tcalls.push(c);
              }
              if (c.conv == 1) {
                this.ccalls.push(c);
              } else {
                this.ncalls.push(c);
              }
              this.calls.push(c);
            }
            this.$store.state.progress = false;
          });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (last_num == 1) return " балл";
      if ([2, 3, 4].includes(last_num)) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(last_num)) return " баллов";
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.man_info {
  align-items: center;
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
}

.panels_info .q-tabs__content {
  overflow-y: scroll;
}
</style>
