<template>
  <q-page class="ub-page">
    <q-card style="margin-bottom: 15px;height: 100%">
      <div class="row align-items-center">
        <div
          class="col-2"
          style="padding-left: 5px"
          v-for="(s, i) in disks"
          :key="i + Math.random()"
        >
          <q-select
            v-model="disk[i]"
            :options="s"
            option-label="name"
            option-value="id"
            size="sm"
            label="Выбрать папку"
            @input="selectDisk(i)"
          />
        </div>
      </div>
    </q-card>
    <q-card style="padding: 5px;height: 100%">
      <div class="row">
        <div class="col-12" style="border: 1px solid;padding: 10px">
          <q-btn
            align="left"
            color="green"
            size="sm"
            label="Преобразовать звонок в чеклист"
            @click="makeScript()"
          />
          <q-btn
            align="right"
            size="sm"
            style="float: right"
            color="blue"
            @click="setCheck()"
            >Сохранить Чеклист
          </q-btn>
          <q-input
            type="textarea"
            style="width: 100%"
            rows="20"
            v-model.lazy="check_list"
            :label="'Чеклист для ' + setDisk.name"
            autofocus
          />
        </div>
      </div>
    </q-card>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "disk_checklist",
  components: {},
  data() {
    return {
      check_list: "",
      setType: null,
      setDisk: {},
      disks: [],
      disk: [],
      dindex: 0
    };
  },
  created() {},
  mounted() {
    this.getCats(0);
  },
  watch: {
    setDisk(val, old) {
      this.getCheck();
    }
  },
  methods: {
    selectDisk(i) {
      this.setDisk = this.disk[i];
      this.disk.splice(i + 1);
      let ind = [];
      for (let i in this.disk) {
        ind.push(this.disk[i].id);
      }
      this.setType = ind.join("_");
      this.getSubCats(i);
    },
    getCats(index) {
      this.disks = [];
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/yandex/stages?mindex=${index}`)
        .then(r => {
          this.disks.push(r.data.data.stages);
          this.disk.push(r.data.data.stages[0]);
          this.$store.state.progress = false;
        })
        .then(() => {
          this.selectDisk(0);
        });
    },
    getCheck() {
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/yandex/get-check?id=${this.setDisk.id}`)
        .then(r => {
          this.check_list = r.data.data;
          this.$store.state.progress = false;
        });
    },
    setCheck() {
      this.$store.state.progress = true;
      this.$h
        .post(`/analitics/yandex/set-check?id=${this.setDisk.id}`, {
          ch: this.check_list
        })
        .then(r => {
          this.check_list = r.data.data;
          this.$store.state.progress = false;
        });
    },
    getSubCats(index) {
      this.disks.splice(1, this.disks.length);
      //this.disk.splice(index +1 , this.disk.length);
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/yandex/sub-stages?mindex=${this.setType}`)
        .then(r => {
          let st = r.data.data.stages;
          for (let i = 0; i < st.length; i++) {
            if (st[i].length) {
              this.disks.push(st[i]);
            }
          }
          this.$store.state.progress = false;
        });
    },
    makeScript() {
      this.$store.state.progress = true;
      this.$h.post(`/groups/make-script`, { text: this.check_list }).then(r => {
        this.check_list = r.data.data;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style></style>
