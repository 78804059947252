<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <bitrix24 v-if="page == 'bit24_crm'" />
        <amo v-else-if="page == 'amo_crm'" />
        <yad v-else-if="page == 'yad'" />
        <domru v-else-if="page == 'domru'" />
        <telfin v-else-if="page == 'telfin'" />
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Bitrix24 from "@/components/managers/crm/bit24.vue";
import Amo from "@/components/managers/crm/amo.vue";
import Yad from "@/components/managers/crm/yad.vue";
import Domru from "@/components/managers/crm/domru.vue";
import Telfin from "@/components/managers/crm/telfin.vue";

export default {
  name: "analitics",
  components: { Telfin, Domru, Yad, Amo, Bitrix24 },
  data() {
    return {
      page: null
    };
  },
  created() {
    this.page = this.$route.params.crm;
    this.$store.state.setType = this.$route.params.crm;
  },
  mounted() {},
  watch: {
    "$route.params.crm"(val) {
      this.page = val;
      this.$store.state.setType = val;
    },
    "$store.state.setType"(val) {
      this.$router.push(`/analytics/${val}`);
    }
  },
  methods: {}
};
</script>

<style></style>
