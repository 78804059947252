<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <label><h5>Настройки подключения Битрикс24</h5> </label>
      </q-card>
      <q-card style="padding: 15px">
        <q-tabs v-model="tab" class="text-teal">
          <q-tab
            v-for="r in tabs"
            :name="r.id"
            :label="r.name"
            :key="r.id"
            dense
          />
        </q-tabs>
      </q-card>
      <q-card style="padding: 15px">
        <q-tab-panels v-model="tab" :draggable="false">
          <q-tab-panel name="main" label="Общие настройки">
            <div class="row" style="padding: 15px">
              <div class="col-8">
                <b
                  >Создайте интеграцию в Битрикс24 (Разработчикам -> Другое ->
                  Входящий вебхук). В правах доступаа выбрать CRM и
                  Пользователи</b
                >
              </div>
              <div class="col-4">
                <q-btn
                  style="margin-bottom: 5px;float: right"
                  color="blue"
                  @click="setInfo()"
                  >Сохранить</q-btn
                >
              </div>
            </div>
            <div class="row" style="padding: 15px">
              <div class="col-4">
                Вебхук для вызова rest api
              </div>
              <div class="col-8">
                <input
                  v-model="settings.url"
                  type="text"
                  placeholder="Урл входящего вебхука"
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="row" style="padding: 15px">
              <div class="col-4">
                Минимальное время звонка от
              </div>
              <div class="col-8">
                <input
                  type="number"
                  v-model="settings.min_time"
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="row" style="padding: 15px">
              <div class="col-4" style="align-content: center;">
                Сущности для обработки
              </div>
              <div class="col-8">
                <q-radio v-model="settings.entity" :val="2" label="Сделки" />
                <q-radio v-model="settings.entity" :val="1" label="Лиды" />
              </div>
            </div>
            <div class="row" style="padding: 15px">
              <q-btn
                color="green"
                align="right"
                size="sm"
                label="Настройка Тегов"
                @click="$router.push('/crm/tags?type=bit24_crm')"
                style="margin-bottom: 15px;"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel name="sub" label="Общие настройки">
            <Bit24stages />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
      <q-dialog v-model="makeCheck" persistent>
        <q-card class="column" style="width: 600px" v-if="checkL == null">
          <q-card-section>
            <div class="text-h6">Вставьте текст звонка</div>
          </q-card-section>

          <q-card-section class="col q-pt-none">
            <q-input v-if="checkL == null" type="textarea" v-model="etcall" />
          </q-card-section>

          <q-card-actions align="right" class="bg-white text-teal">
            <q-btn color="red" label="Закрыть" v-close-popup />
            <q-btn color="blue" label="Преобразовать" @click="makeScript" />
          </q-card-actions>
        </q-card>
        <q-card class="column" style="width: 600px" v-else-if="checkL != null">
          <q-card-section>
            <div class="text-h6">Скопируйте чеклист</div>
          </q-card-section>

          <q-card-section class="col q-pt-none">
            <div>{{ checkL }}</div>
          </q-card-section>

          <q-card-actions align="right" class="bg-white text-teal">
            <q-btn color="blue" label="Закрыть" v-close-popup />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </q-page>
</template>

<script>
import Bit24stages from "@/components/crm-setting/stages-bit24.vue";
import { Notify } from "quasar";

export default {
  name: "bitrix24",
  components: { Bit24stages },
  data() {
    return {
      tabs: [
        { id: "main", name: "Общие настройки" },
        { id: "sub", name: "Стадии и скрипты" }
      ],
      tab: "main",
      settings: {
        uri: ""
      },
      etcall: "",
      checkL: null,
      checklists: {
        in: ""
      },
      makeCheck: false
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  watch: {
    makeCheck(val) {
      if (val == false) {
        this.checklists.in = this.checkL;
        this.checkL = null;
      }
    }
  },
  methods: {
    getInfo() {
      this.$store.state.progress = true;
      this.$h.get(`/groups/get-bit24`).then(r => {
        this.settings = r.data.data.creds;
        this.checklists = r.data.data.checklists;
        this.$store.state.progress = false;
      });
    },
    setInfo() {
      /*      if(this.settings.type_tel==null){
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Выберете телефонию!",
            timeout: 2000
          });
        return
      }*/
      this.$store.state.progress = true;
      this.$h
        .post(`/groups/get-bit24`, {
          credentials: this.settings,
          check: this.checklists
        })
        .then(r => {
          if (r.data.data != null) {
            this.settings = r.data.data.creds;
            this.checklists = r.data.data.checklists;
          }
          this.$store.state.progress = false;
        });
    },
    makeScript() {
      this.$store.state.progress = true;
      this.$h.post(`/groups/make-script`, { text: this.etcall }).then(r => {
        this.checkL = r.data.data;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
