<template>
  <q-page class="ub-page">
    <router-view />
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Yad from "@/components/disk/yad.vue";

export default {
  name: "group_info",
  components: {},
  data() {
    return {
      disk: null
    };
  },
  created() {
    if (this.$route.query.type) {
      this.disk = this.$route.query.type;
    }
  },
  mounted() {},
  watch: {
    "$route.query.type"(val) {
      this.disk = val;
    },
    methods: {}
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
