<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <div class="row" style="padding-bottom:20px ">
          <div class="col-2">
            <q-select
              v-model="group.ya_disk"
              :options="yad"
              option-label="name"
              option-value="name"
              label="Яндекс диск"
            />
          </div>
          <div class="col-3">
            <q-input
              type="text"
              style="width: 100%"
              v-model="group.id_sheet_table"
              label="Ид таблицы"
            />
          </div>
          <div class="col-4"></div>
          <div class="col-1">
            <q-btn style="margin-bottom: 5px" color="blue" @click="setGroups()"
              >Сохранить</q-btn
            >
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-2"></div>
        </div>
        <q-tabs v-model="tab" class="text-teal">
          <q-tab
            v-for="r in tabs"
            :name="r.id"
            :label="r.name"
            :key="r.id"
            dense
          />
        </q-tabs>
        <q-tab-panels
          v-if="!$store.state.progress"
          v-model="tab"
          :draggable="false"
        >
          <q-tab-panel
            v-for="d in tabs"
            :name="d.id"
            :label="d.name"
            :key="d.id + Math.random()"
          >
            <div class="row" style="padding-bottom: 15px">
              <div class="col-3" v-if="tab !== 'company_checklist'">
                <q-select
                  size="sm"
                  dense
                  v-model="scriptMod[d.id].m"
                  :options="mods"
                  option-label="id"
                  option-value="id"
                  label="Модель"
                />
              </div>
              <div class="col-1" v-if="tab !== 'company_checklist'">
                <q-checkbox
                  size="sm"
                  v-model="scriptMod[d.id].u"
                  label="file_search"
                />
              </div>
            </div>
            <q-input
              :name="d.id"
              :autofocus="false"
              type="textarea"
              :key="d.id + Math.random() + 't'"
              style="border: 1px solid grey;width: 100%;"
              rows="25"
              v-model="newGroup[tab]"
              :label="d.name"
            />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "group_info",
  components: {},
  data() {
    return {
      splitterModel: 10,
      group: {},
      mods: [],
      scriptMod: {
        transcript: {
          u: false,
          m: ""
        },
        call_script: {
          u: false,
          m: ""
        },
        recommendation: {
          u: false,
          m: ""
        },
        etalon_text: {
          u: false,
          m: ""
        },
        errors_text: {
          u: false,
          m: ""
        },
        test_text: {
          u: false,
          m: ""
        },
        audio_promt: {
          u: false,
          m: ""
        }
      },
      newGroup: {},
      gid: 0,
      yad: [],
      tab: null,
      tabs: [
        {
          id: "transcript",
          name: "Транскрипт",
          text: ""
        },
        {
          id: "call_script",
          name: "Скрипт звонка",
          text: ""
        },
        {
          id: "recommendation",
          name: "Рек-ция к звонку",
          text: ""
        },
        {
          id: "etalon_text",
          name: "Эталон",
          text: ""
        },
        {
          id: "errors_text",
          name: "Ошибки",
          text: ""
        },
        {
          id: "test_text",
          name: "Тесты",
          text: ""
        },
        {
          id: "audio_promt",
          name: "Аудио промт",
          text: ""
        },
        {
          id: "company_checklist",
          name: "Чеклист",
          text: ""
        }
      ]
    };
  },
  created() {
    if (this.$route.query.gid) {
      this.gid = this.$route.query.gid;
      for (let t in this.tabs) {
        let i = this.tabs[t].id;
        this.scriptMod[i] = { m: "", u: false };
      }
    }
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    tab(val) {
      this.newGroup[val] = this.group[val];
    }
  },
  methods: {
    setGroups() {
      this.$store.state.progress = true;
      for (let i in this.newGroup) {
        this.group[i] = this.newGroup[i];
      }
      this.$h
        .post("/settings/set-group", { g: this.group, sm: this.scriptMod })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    },
    getInfo() {
      this.$store.state.progress = true;
      this.$h.get(`/settings/get-bot-group?gid=${this.gid}`).then(r => {
        this.group = { ...r.data.data.g };
        this.tab = "transcript";
        const m = { ...r.data.data.sm };
        for (let s in m) {
          this.scriptMod[s] = m[s];
        }

        this.mods = r.data.data.mods;
        this.yad = r.data.data.ya;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
