<template>
  <div class="q-gutter-y-md" style="max-width: 100%;">
    <q-card style="padding: 15px">
      <label><h5>Настройки подключения телефонии АТС Дом.ру Бизнес</h5></label>
      <div class="row" style="padding: 15px">
        <div class="col-4">
          Для авторизации необходимо передать ключ в заголовке X‑API‑KEY.
          Получить ключ можно при настройке интеграции, он указан в поле «Ключ
          для авторизации в АТС».
        </div>
        <div class="col-8">
          <q-btn
            style="margin-bottom: 5px;float: right"
            color="blue"
            @click="setInfo()"
            >Сохранить
          </q-btn>
        </div>
      </div>
      <div class="row" style="padding: 15px">
        <div class="col-4">
          Урл сервера
        </div>
        <div class="col-8">
          <input
            v-model="settings.url"
            type="text"
            name="url"
            placeholder="https://{domen}.domru.biz/"
            style="width: 100%"
          />
        </div>
      </div>
      <div class="row" style="padding: 15px">
        <div class="col-4">
          X‑API‑KEY
        </div>
        <div class="col-8">
          <input
            type="text"
            name="apykey"
            v-model="settings.api_key"
            style="width: 100%"
          />
        </div>
      </div>
      <div class="row" style="padding: 15px">
        <div class="col-4">
          Минимальное время звонка от
        </div>
        <div class="col-8">
          <input
            name="mintime"
            type="number"
            v-model="settings.min_time"
            style="width: 100%"
          />
        </div>
      </div>
    </q-card>
    <q-card style="padding: 15px">
      <q-btn
        color="green"
        align="right"
        size="sm"
        label="Настройка Тегов"
        @click="$router.push('/telefony/tags?type=domru')"
        style="margin-bottom: 15px;"
      /><br />
      <label>Чеклисты</label>
      <div class="row" style="padding: 15px">
        <div class="col-6" style="border: 1px solid;padding: 10px">
          <q-btn
            color="blue"
            size="sm"
            label="Преобразовать звонок в чеклист"
            @click="makeCh('in')"
          />
          <q-input
            type="textarea"
            v-model="checklists.in"
            label="Чеклист для входящий"
          />
        </div>
        <div class="col-6" style="border: 1px solid;padding: 10px">
          <q-btn
            color="blue"
            size="sm"
            label="Преобразовать звонок в чеклист"
            @click="makeCh('out')"
          />
          <q-input
            type="textarea"
            v-model="checklists.out"
            label="Чеклист для исходящий"
          />
        </div>
      </div>
    </q-card>

    <q-dialog v-model="makeCheck" persistent>
      <q-card class="column" style="width: 600px" v-if="checkL == null">
        <q-card-section>
          <div class="text-h6">Вставьте текст звонка</div>
        </q-card-section>

        <q-card-section class="col q-pt-none">
          <q-input v-if="checkL == null" type="textarea" v-model="etcall" />
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn color="red" label="Закрыть" v-close-popup />
          <q-btn color="blue" label="Преобразовать" @click="makeScript" />
        </q-card-actions>
      </q-card>
      <q-card class="column" style="width: 600px" v-else-if="checkL != null">
        <q-card-section>
          <div class="text-h6">Скопируйте чеклист</div>
        </q-card-section>

        <q-card-section class="col q-pt-none">
          <div>{{ checkL }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn color="blue" label="Закрыть" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify } from "quasar";
import { THEME_KEY } from "vue-echarts";

export default {
  name: "domru",
  components: {},
  data() {
    return {
      settings: {
        url: "",
        api_key: "",
        min_time: 0
      },
      checklists: {
        in: "",
        out: ""
      },
      typeCh: "in",
      makeCheck: false,
      etcall: "",
      checkL: null
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  watch: {
    makeCheck(val) {
      if (val == false) {
        if (this.typeCh == "in") {
          this.checklists.in = this.checkL;
          this.checkL = null;
        } else {
          this.checklists.out = this.checkL;
          this.checkL = null;
        }
      }
    }
  },
  methods: {
    makeCh(t) {
      this.typeCh = t;
      this.makeCheck = true;
    },
    getInfo() {
      this.$store.state.progress = true;
      this.$h.get(`/groups/get-domru`).then(r => {
        if (r.data.data != null) {
          this.checklists = r.data.data.checklists;
          this.settings = r.data.data.creds;
        }
        this.$store.state.progress = false;
      });
    },

    setInfo() {
      this.$store.state.progress = true;
      this.$h
        .post(`/groups/get-domru`, {
          credentials: this.settings,
          check: this.checklists
        })
        .then(r => {
          if (r.data.data != null) {
            this.settings = r.data.data.creds;
            this.checklists = r.data.data.checklists;
          }
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    },
    makeScript() {
      this.$store.state.progress = true;
      this.$h.post(`/groups/make-script`, { text: this.etcall }).then(r => {
        this.checkL = r.data.data;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
