<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <div
          class="row"
          style="min-height: 75px;"
          v-if="!$store.state.progress && managerInfo"
        >
          <div class="col-1 man_info">
            <q-avatar
              :color="$colors[this.color]"
              size="75px"
              v-if="managerInfo.user_photo"
            >
              <img
                :alt="managerInfo.first_name"
                :src="$h.defaults.baseURL + managerInfo.user_photo"
              />
            </q-avatar>
            <q-avatar :color="color" size="75px" v-else>
              <b style="font-size: 12px">{{
                managerInfo.first_name
                  ? managerInfo.first_name.substr(0, 2)
                  : ""
              }}</b>
            </q-avatar>
            <br />
            <div :class="'text-' + color">
              {{ managerInfo.first_name }}
            </div>
          </div>
          <div class="col-2 man_info">
            <label class="reit_name">Рейтинг</label>
            <div :class="'text-' + color">
              {{
                managerInfo.progress + dayTitle(parseInt(managerInfo.progress))
              }}
            </div>
          </div>
          <div
            class="col-2 man_info"
            style="text-align: center;cursor: pointer"
            @click="
              $router.push(
                `/managers/telfin/info/calls/${managerId}`
              )
            "
          >
            <label class="reit_name">Конверсия</label>
            <div
              :style="'text-align: center;width: 100%; color:' + color"
            >
              {{ managerInfo.f }} звонков
            </div>

            <q-linear-progress
              size="20px"
              :value="managerInfo.conv"
              :color="color"
              style="border-radius: 25px"
            >
              <div
                class="absolute-full flex flex-center"
                style="border-radius: 25px"
              >
                <q-badge
                  color="white"
                  :text-color="color"
                  :label="(managerInfo.conv * 100).toFixed(0) + '%'"
                  style="border-radius: 25px"
                />
              </div>
            </q-linear-progress>
          </div>
          <div
            style="cursor: pointer"
            class="col-2 man_info"
            @click="
              $router.push(
                `/managers/telfin/info/tests/${managerId}`
              )
            "
          >
            <label class="reit_name">Пройденно тестов</label>
            <div :class="'text-' + color">
              {{ managerInfo.trains.answ + "/" + managerInfo.trains.all }}
            </div>
          </div>
        </div>
      </q-card>
    </div>
    <telfinCalls :manager-id="managerId" :color="color" v-if="page == 'calls'" />
    <telfinTests :manager-id="managerId" :color="color" v-else-if="page == 'tests'" />
  </q-page>
</template>

<script>
import telfinCalls from "@/components/managers/info/type/telfin_calls.vue";
import telfinTests from "@/components/managers/info/type/telfin_tests.vue";

export default {
  name: "info_telfin",
  components: { telfinTests, telfinCalls },
  data() {
    return {
      status: null,
      managerId: 0,
      managerInfo: null,
      page: null,
      color: 0,
      setMonth: 0
    };
  },
  created() {
    this.page = this.$route.params.type;
    this.managerId = parseInt(this.$route.params.id);
    this.setMonth = this.$store.state.setMonth;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getManagerInfo();
    },
    getManagerInfo() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/analitics/telfin/manager-info?id=${this.managerId}&m=${this.setMonth}`
        )
        .then(r => {
          this.managerInfo = r.data.data.m;
          this.color = this.managerInfo.color
          this.$store.state.progress = false;
        });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (Math.abs(last_num) == 1) return " балл";
      if ([2, 3, 4].includes(Math.abs(last_num))) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(Math.abs(last_num))) return " баллов";
    }
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    },
    "$route.params.type"() {
      this.page = this.$route.params.type;
    }
  }
};
</script>

<style></style>
