<template>
  <div class="q-gutter-y-md" style="max-width: 100%;">
    <q-card style="padding: 15px" v-if="!$store.state.progress">
      <q-splitter
        v-model="fileL"
        style="height: 500px; overflow-y:scroll;align-items: start;text-align: left"
      >
        <template v-slot:before>
          <q-tabs
            v-model="test"
            vertical
            :class="'panels_info text-' + color"
          >
            <q-tab
              v-for="(r, index) in tests"
              :name="index"
              style="min-width: 100px"
              :label="r.client"
              :key="r.file_id"
            />
          </q-tabs>
        </template>

        <template v-slot:after>
          <q-tab-panels
            v-model="test"
            animated
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
            :draggable="false"
            v-if="tests.length"
          >
            <q-tab-panel
              v-for="(d, index) in tests"
              :name="index"
              :label="d.client"
              :key="d.id + Math.random()"
            >
              <h6 style="text-align: center;padding-bottom: 15px">
                {{ d.name }}
              </h6>
              <div class="q-gutter-y-md">
                <div class="row">
                  <div style="text-align: center" class="col-12">
                    <b>
                      <span v-if="!d.quest.answ" style="color: red"
                        >Не пройден</span
                      >
                      <span v-else style="color: green">Пройден</span></b
                    >
                  </div>
                  <div style="text-align: center" class="col-12">
                    <b>{{ d.quest.question }}</b>
                  </div>
                  <div
                    style="padding: 15px"
                    class="col-6"
                    v-for="q in d.quest.answers"
                    :key="q.id"
                  >
                    {{ q.answer }}
                  </div>
                </div>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </template>
      </q-splitter>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "yad-tests",
  props: {
    subTitle: null,
    color:{
      type:String
    },
    managerId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      setMouth: new Date().getMonth() + 1,
      layout: "dense",
      side: "right",
      fileL: 20,
      index: 0,
      messages: [],
      managerInfo: null,
      test: 0,
      tests: [],
      file: 0
    };
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    }
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
    if (this.$route.query.id) {
      this.managerId = this.$route.query.id;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getManagerTests();
    },
    getManagerTests() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/analitics/yandex/manager-tests?id=${this.managerId}&m=${this.setMonth}`
        )
        .then(r => {
          this.tests = r.data.data.tests;
          this.$store.state.progress = false;
        });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (last_num == 1) return " балл";
      if ([2, 3, 4].includes(last_num)) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(last_num)) return " баллов";
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.man_info {
  align-items: center;
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
}

.panels_info .q-tabs__content {
  overflow-y: scroll;
}
</style>
