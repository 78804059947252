<template>
  <q-page class="ub-page">
    <Info_bit24 v-if="page == 'bit24-crm'" />
    <Info_amo v-else-if="page == 'amo-crm'" />
    <Info_yad v-else-if="page == 'yandex'" />
    <Info_domru v-else-if="page == 'domru'" />
    <Info_telfin v-else-if="page == 'telfin'" />
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Info_bit24 from "@/components/managers/info/info_bit24.vue";
import Info_amo from "@/components/managers/info/info_amo.vue";
import Info_yad from "@/components/managers/info/info_yad.vue";
import Info_domru from "@/components/managers/info/info_domru.vue";
import Info_telfin from "@/components/managers/info/info_telfin.vue";

export default {
  name: "managers",
  components: { Info_telfin, Info_domru, Info_yad, Info_amo, Info_bit24 },
  data() {
    return {
      page: null
    };
  },
  created() {
    this.page = this.$route.params.crm;
  },
  mounted() {},
  watch: {
    "$route.params.crm"(val) {
      this.page = val;
    },
    "$store.state.setType"(val) {
      this.$router.push(`/analytics/${val}`);
    }
  },
  methods: {}
};
</script>

<style></style>
