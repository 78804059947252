<template>
  <q-page class="ub-page">
    <router-view />
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Telfin from "@/components/telefony/telfin.vue";
import Domru from "@/components/telefony/domru.vue";

export default {
  name: "telefony",
  components: {},
  data() {
    return {
      telpage: this.$route.query.type
    };
  },
  created() {
    if (this.$route.query.type) {
      this.telpage = this.$route.query.type;
    }
  },
  mounted() {},
  watch: {
    "$route.query.type"(val) {
      this.telpage = val;
    }
  },
  methods: {}
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
