<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px"></q-card>
      <q-card style="padding: 15px">
        <div class="q-pa-md" v-if="!loading">
          <q-table
            :loading="loading"
            title="История пополнений"
            :data="data"
            dense
            :columns="columns"
            row-key="name"
            :pagination="pagination"
            hide-bottom
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="date" :props="props">
                  {{ props.row.date }}
                </q-td>
                <q-td key="summ" :props="props">
                  {{ props.row.summ }}
                </q-td>
                <q-td key="user" :props="props">
                  {{ props.row.user }}
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "history",
  components: {},
  data() {
    return {
      loading: true,
      ready: true,
      columns: [],
      data: [],
      pagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 12
        // rowsNumber: xx if getting data from a server
      }
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  watch: {},
  methods: {
    getInfo() {
      this.$store.state.progress = true;
      this.$h
        .get("/billing/get-history")
        .then(r => {
          this.data = r.data.data.d;
          this.columns = r.data.data.c;
        })
        .then(() => {
          this.$store.state.progress = false;
          this.loading = false;
        });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
