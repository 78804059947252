<template>
  <router-view />
</template>

<script>
export default {
  name: "group_info",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {
    methods: {}
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
