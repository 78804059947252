<template>
  <q-page class="ub-page">
    <Amo_crm v-if="$store.state.setType == 'amo_crm'" />
    <Bi24_crm v-else-if="$store.state.setType == 'bit24_crm'" />
    <Yad v-else-if="$store.state.setType == 'yad'" />
    <Default v-else />
  </q-page>
</template>

<script>
import LineChart from "@/chart/LineChart.vue";
import Default from "@/components/analitiks/default.vue";
import Amo_crm from "@/components/analitiks/amo_crm.vue";
import Bi24_crm from "@/components/analitiks/bit24_crm.vue";
import Yad from "@/components/analitiks/yad.vue";

export default {
  name: "main_page",
  components: { Yad, Bi24_crm, Amo_crm, Default },
  props: {
    subTitle: null
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}
</style>
