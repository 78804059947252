<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <div
          class="row"
          style="min-height: 75px;"
          v-if="!$store.state.progress && managerInfo"
        >
          <div class="col-1 man_info">
            <q-avatar
              :color="$colors[this.color]"
              size="75px"
              v-if="managerInfo.user_photo"
            >
              <img
                :alt="managerInfo.first_name"
                :src="$h.defaults.baseURL + managerInfo.user_photo"
              />
            </q-avatar>
            <q-avatar :color="$colors[color]" size="75px" v-else>
              <b style="font-size: 12px">{{
                managerInfo.first_name
                  ? managerInfo.first_name.substr(0, 2)
                  : ""
              }}</b>
            </q-avatar>
            <br />
            <div :class="'text-' + $colors[color]">
              {{ managerInfo.first_name }}
            </div>
          </div>
          <div class="col-2 man_info">
            <label class="reit_name">Рейтинг</label>
            <div :class="'text-' + $colors[color]">
              {{
                managerInfo.progress + dayTitle(parseInt(managerInfo.progress))
              }}
            </div>
          </div>
          <div
            class="col-2 man_info"
            style="text-align: center;cursor: pointer"
            @click="
              $router.push(
                `/analytics/manager-calls?id=${managerId}&ind=${color}`
              )
            "
          >
            <label class="reit_name">Конверсия</label>
            <div
              :style="'text-align: center;width: 100%; color:' + $colors[color]"
            >
              {{ managerInfo.f }} звонков
            </div>

            <q-linear-progress
              size="20px"
              :value="managerInfo.conv"
              :color="$colors[color]"
              style="border-radius: 25px"
            >
              <div
                class="absolute-full flex flex-center"
                style="border-radius: 25px"
              >
                <q-badge
                  color="white"
                  :text-color="$colors[color]"
                  :label="(managerInfo.conv * 100).toFixed(0) + '%'"
                  style="border-radius: 25px"
                />
              </div>
            </q-linear-progress>
          </div>
          <div
            style="cursor: pointer"
            class="col-2 man_info"
            @click="
              $router.push(
                `/analytics/manager-tests?id=${managerId}&ind=${color}`
              )
            "
          >
            <label class="reit_name">Пройденно тестов</label>
            <div :class="'text-' + $colors[color]">
              {{ managerInfo.trains.answ + "/" + managerInfo.trains.all }}
            </div>
          </div>
        </div>
      </q-card>
      <q-card
        style="padding: 15px"
        v-if="!$store.state.progress && managerInfo"
      >
        <q-splitter
          v-model="fileL"
          style="height: 500px; overflow-y:scroll;align-items: start;text-align: left"
        >
          <template v-slot:before>
            <q-tabs
              v-model="test"
              vertical
              :class="'panels_info text-' + $colors[color]"
            >
              <q-tab
                v-for="(r, index) in tests"
                :name="index"
                style="min-width: 100px"
                :label="r.client"
                :key="r.file_id"
              />
            </q-tabs>
          </template>

          <template v-slot:after>
            <q-tab-panels
              v-model="test"
              animated
              vertical
              transition-prev="jump-up"
              transition-next="jump-up"
              :draggable="false"
              v-if="tests.length"
            >
              <q-tab-panel
                v-for="(d, index) in tests"
                :name="index"
                :label="d.client"
                :key="d.id + Math.random()"
              >
                <h6 style="text-align: center;padding-bottom: 15px">
                  {{ d.name }}
                </h6>
                <div class="q-gutter-y-md">
                  <div class="row">
                    <div style="text-align: center" class="col-12">
                      <b>
                        <span v-if="!d.quest.answ" style="color: red"
                          >Не пройден</span
                        >
                        <span v-else style="color: green">Пройден</span></b
                      >
                    </div>
                    <div style="text-align: center" class="col-12">
                      <b>{{ d.quest.question }}</b>
                    </div>
                    <div
                      style="padding: 15px"
                      class="col-6"
                      v-for="q in d.quest.answers"
                      :key="q.id"
                    >
                      {{ q.answer }}
                    </div>
                  </div>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "manager-info",
  props: {
    subTitle: null
  },
  data() {
    return {
      setMouth: new Date().getMonth() + 1,
      layout: "dense",
      side: "right",
      fileL: 20,
      index: 0,
      messages: [],
      managerId: 0,
      color: 0,
      managerInfo: null,
      test: 0,
      tests: [],
      file: 0
    };
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    }
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
    if (this.$route.query.id) {
      this.managerId = this.$route.query.id;
      this.color = this.$route.query.ind || 0;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getManagerInfo();
      this.getManagerTests();
    },
    getManagerInfo() {
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/manager-info?id=${this.managerId}&m=${this.setMonth}`)
        .then(r => {
          this.managerInfo = r.data.data.m;
          this.$store.state.progress = false;
        });
    },
    getManagerTests() {
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/manager-tests?id=${this.managerId}&m=${this.setMonth}`)
        .then(r => {
          this.tests = r.data.data.tests;
          this.$store.state.progress = false;
        });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (last_num == 1) return " балл";
      if ([2, 3, 4].includes(last_num)) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(last_num)) return " баллов";
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}

.man_info {
  align-items: center;
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
}

.panels_info .q-tabs__content {
  overflow-y: scroll;
}
</style>
