<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <BarChart :ch-options="options" style="height: 350px" v-if="ready" />
      </q-card>
      <q-card style="padding: 15px">
        <div class="q-pa-md" v-if="!loading">
          <q-table
            :loading="loading"
            style="padding: 20px"
            title="Использование финансов"
            :data="data"
            dense
            :columns="columns"
            row-key="name"
            :pagination="pagination"
            hide-bottom
          >
            <template v-slot:body="props">
              <q-tr :props="props" style="padding: 10px">
                <q-td key="month" :props="props">
                  {{ props.row.month }}
                </q-td>
                <q-td key="fcount" :props="props">
                  {{ props.row.fcount }}
                </q-td>
                <q-td key="pcount1" :props="props">
                  {{ props.row.pcount1 }}
                </q-td>
                <q-td key="pcount2" :props="props">
                  {{ props.row.pcount2 }}
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import BarChart from "@/chart/BarChart.vue";

export default {
  name: "current",
  components: { BarChart },
  data() {
    return {
      loading: true,
      ready: true,
      columns: [],
      data: [],
      options: {
        color: ["#488cf6", "#0bd1f5"],
        legend: {
          padding: [0, 0, 30, 30],
          width: "80%",
          type: "scroll",
          data: []
        },
        xAxis: {
          step: 1,
          type: "category",
          data: [],
          axisPointer: {
            type: "shadow"
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {}
        },
        series: []
      },
      mounths: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      setMonth: new Date().getMonth(),
      pagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 13
        // rowsNumber: xx if getting data from a server
      }
    };
  },
  created() {
    this.setMonth = this.$store.state.setMonth;
  },
  mounted() {
    this.getStateT();
    this.getInfo();
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.options.series.splice(0, this.options.series.length);
      this.getStateT();
    }
  },
  methods: {
    getStateT() {
      this.ready = false;
      this.$store.state.progress = true;
      this.$h.get(`/billing/get-info-month?m=${this.setMonth}`).then(r => {
        this.options.xAxis.data = r.data.data.d;
        let cd1 = r.data.data.cd1;
        let cd2 = r.data.data.cd2;
        let cd3 = r.data.data.cd3;

        this.options.series.push({
          name: cd1.title,
          type: "bar",
          data: cd1.data
        });
        this.options.series.push({
          name: cd2.title,
          type: "bar",
          data: cd2.data
        });
        this.options.series.push({
          name: cd3.title,
          type: "bar",
          data: cd3.data
        });
        this.ready = true;
        this.$store.state.progress = false;
      });
    },
    getInfo() {
      this.$store.state.progress = true;
      this.$h
        .get("/billing/get-info")
        .then(r => {
          this.columns = r.data.data.c;
          this.data = r.data.data.d;
        })
        .then(() => {
          this.$store.state.progress = false;
          this.loading = false;
        });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
