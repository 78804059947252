<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <q-tabs v-model="tab" class="text-teal">
          <q-tab v-for="r in tabs" :name="r.id" :label="r.name" :key="r.id" />
        </q-tabs>
      </q-card>
      <q-card style="padding: 15px">
        <h6>
          Текущий баланс : {{ balance }}
          <q-btn
            style="float: right"
            label="Пополнить"
            color="blue"
            @click="getRecPayBalance"
          />
        </h6>
      </q-card>
      <q-card style="padding: 15px">
        <History v-if="tab == 'history'" />
        <Current v-else-if="tab == 'current'" />
      </q-card>
    </div>
    <q-dialog v-model="showMBalance">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
          <div class="text-h5 text-center">Рекомендуемое пополнение</div>
          <div class="text-h6 text-center">
            Средняя продолжительность звонков: {{ recBalance.duration }} мин
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="recBalance.tarif"
              disable
              :options="tarifs"
              label="Тариф"
            />
            <q-input
              filled
              v-model="recBalance.calls"
              label="Звонков"
              type="number"
            />
            <q-input
              filled
              type="number"
              v-model="recBalance.summ"
              label="Сумма"
            />
            <div>
              <q-btn
                label="Оправить заявку"
                color="primary"
                @click="sendRecPayBalance"
              />
              <q-btn
                label="Отменить"
                color="primary"
                flat
                class="q-ml-sm"
                @click="showMBalance = false"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="alertW">
      <q-card>
        <q-card-section>
          <div class="text-h5 text-center">Благодарим Вас за запрос счета!</div>
        </q-card-section>

        <q-card-section class="q-pt-none text-center">
          Документ будет направлен вам нашим менеджером на почту (почта
          аккаунта) и в вашу группу <b>{{ $store.state.user.telega }}</b>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Current from "@/components/billing/current.vue";
import History from "@/components/billing/history.vue";

export default {
  name: "billing",
  components: { History, Current },
  data() {
    return {
      alertW: false,
      tab: "current",
      showMBalance: false,
      balance: 0.0,

      tarifs: [
        {
          label: "15 рублей",
          value: "15"
        },
        {
          label: "18 рублей",
          value: "18"
        }
      ],
      tabs: [
        { id: "current", name: "Текущие расходы" },
        { id: "history", name: "История пополнений" }
      ],
      recBalance: {
        tarif: {
          label: "15 рублей",
          value: "15"
        },
        duration: 0,
        calls: 0,
        summ: 0
      }
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    console.log();
  },
  watch: {
    "recBalance.summ"(val) {
      this.recBalance.calls = (val / this.recBalance.tarif.value).toFixed(0);
    },
    "recBalance.calls"(val) {
      this.recBalance.summ = (val * this.recBalance.tarif.value).toFixed(0);
    }
  },
  methods: {
    getInfo() {
      this.$store.state.progress = true;
      this.$h.get("/billing/get-balance").then(r => {
        this.balance = r.data.data;
      });
    },
    getRecPayBalance() {
      this.$store.state.progress = true;
      this.$h.get("/billing/rec-balance").then(r => {
        this.recBalance = r.data.data;
        this.showMBalance = true;
        this.$store.state.progress = false;
      });
    },

    sendRecPayBalance() {
      this.$store.state.progress = true;
      this.$h.post("/billing/rec-balance", { rb: this.recBalance }).then(() => {
        this.alertW = true;
        this.showMBalance = false;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
