<template>
  <div class="q-gutter-y-md" style="max-width: 100%;">
    <q-card style="padding: 15px">
      <h5>
        Настройки для <b>{{ settings.name }}</b>
      </h5>
    </q-card>
    <q-card style="padding: 15px">
      <q-tabs v-model="tab" class="text-teal">
        <q-tab
          v-for="r in tabs"
          :name="r.id"
          :label="r.name"
          :key="r.id"
          dense
        />
      </q-tabs>
    </q-card>
    <q-card style="padding: 15px">
      <q-tab-panels v-model="tab" :draggable="false">
        <q-tab-panel name="main" label="Общие настройки">
          <div class="row" style="padding: 15px">
            <div class="col-2">
              Минимальное время звонка от
            </div>
            <div class="col-3">
              <input
                type="number"
                v-model="settings.min_time"
                style="width: 100%"
              />
            </div>
            <div class="col-5">
              <q-btn
                style="margin-bottom: 5px;float: right"
                color="blue"
                @click="setInfo()"
                >Сохранить настройки
              </q-btn>
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-4" style="margin-top: 10px">
              <q-checkbox
                label="Использовать название папки со звонками как имя менеджера"
                v-model="settings.last_dir_name"
              />
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-3">
              <q-btn
                color="green"
                align="right"
                size="sm"
                label="Настройка Тегов"
                @click="$router.push('/disk/tags?type=yad')"
                style="margin-bottom: 15px;"
              /><br /><label>Чеклист</label>
            </div>
          </div>
          <div class="row" style="padding: 15px">
            <div class="col-12" style="border: 1px solid;padding: 10px">
              <q-btn
                color="blue"
                size="sm"
                label="Преобразовать звонок в чеклист"
                @click="makeCh()"
              />
              <q-input
                type="textarea"
                rows="25"
                v-model="checklist"
                label="Чеклист"
              />
            </div>
          </div>
        </q-tab-panel>
        <q-tab-panel name="sub" label="Общие настройки">
          <Disk_checklist />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-dialog v-model="makeCheck" persistent>
      <q-card class="column" style="width: 600px" v-if="checkL == null">
        <q-card-section>
          <div class="text-h6">Вставьте текст звонка</div>
        </q-card-section>

        <q-card-section class="col q-pt-none">
          <q-input v-if="checkL == null" type="textarea" v-model="etcall" />
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn color="red" label="Закрыть" v-close-popup />
          <q-btn color="blue" label="Преобразовать" @click="makeScript" />
        </q-card-actions>
      </q-card>
      <q-card class="column" style="width: 600px" v-else-if="checkL != null">
        <q-card-section>
          <div class="text-h6">Скопируйте чеклист</div>
        </q-card-section>

        <q-card-section class="col q-pt-none">
          <div>{{ checkL }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn color="blue" label="Закрыть" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify } from "quasar";
import Disk_checklist from "@/components/disk/disk_checklist.vue";

export default {
  name: "yad",
  components: { Disk_checklist },
  data() {
    return {
      tabs: [
        { id: "main", name: "Общие настройки" },
        { id: "sub", name: "Папки и скрипты" }
      ],
      tab: "main",
      settings: {
        min_time: 0
      },
      checklist: "",
      makeCheck: false,
      etcall: "",
      checkL: null
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  watch: {
    makeCheck(val) {
      if (val == false) {
        if (this.typeCh == "in") {
          this.checklists.in = this.checkL;
          this.checkL = null;
        } else {
          this.checklists.out = this.checkL;
          this.checkL = null;
        }
      }
    }
  },
  methods: {
    makeCh(t) {
      this.typeCh = t;
      this.makeCheck = true;
    },
    getInfo() {
      this.$store.state.progress = true;
      this.$h.get(`/groups/get-yad`).then(r => {
        if (r.data.data != null) {
          this.settings = r.data.data.settings;
          this.settings.last_dir_name = this.settings.last_dir_name
            ? true
            : false;
          this.checklist = r.data.data.checklist;
        } else {
          this.$router.push({ path: `/` });
        }
        this.$store.state.progress = false;
      });
    },
    setInfo() {
      this.$store.state.progress = true;
      this.settings.last_dir_name = this.settings.last_dir_name ? 1 : 0;
      this.$h
        .post(`/groups/set-yad`, { set: this.settings, check: this.checklist })
        .then(r => {
          if (r.data.data !== null) {
            this.settings = r.data.data.settings;
            this.settings.last_dir_name = this.settings.last_dir_name
              ? true
              : false;
            this.checklist = r.data.data.checklist;
          } else {
            this.$router.push({ path: `/` });
          }
          console.log(r.data.data);
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    },
    makeScript() {
      this.$store.state.progress = true;
      this.$h.post(`/groups/make-script`, { text: this.etcall }).then(r => {
        this.checkL = r.data.data;
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
