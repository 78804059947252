<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <q-tabs v-model="tab" class="text-teal">
          <q-tab
            v-for="r in tabs"
            :name="r.id"
            :label="r.name"
            :key="r.id"
            dense
          />
        </q-tabs>
        <q-tab-panels
          v-if="!$store.state.progress"
          v-model="tab"
          :draggable="false"
        >
          <q-tab-panel name="setting" label="Настройки">
            <div class="row" style="padding-bottom: 15px">
              <div class="col-12">
                <q-btn
                  style="margin-bottom: 5px;float: right"
                  color="blue"
                  @click="setSettings()"
                  >Сохранить</q-btn
                >
              </div>
              <div class="col-12">
                <div style="padding-top: 15px" class="row">
                  <div class="col-4">
                    <label>Пояс GMT</label>
                  </div>
                  <div class="col-8">
                    <select v-model="settings.time_zone">
                      <option v-for="tz in time_zones" :key="tz">{{
                        tz
                      }}</option>
                    </select>
                  </div>
                </div>
                <hr />
                <div class="row" style="padding-top: 15px">
                  <div class="col-4">
                    <label
                      >Конец рабочего дня (отправка тестов, забор аудио для
                      обработки)</label
                    >
                  </div>
                  <div class="col-8">
                    <select v-model="settings.end_work_time">
                      <option v-for="wt in work_time" :key="wt + wt">{{
                        wt
                      }}</option>
                    </select>
                  </div>
                </div>
                <hr />
                <div class="row" style="padding-top: 15px">
                  <div class="col-4">
                    <label>Минимальная длинна звонка (сек)</label>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      v-model.lazy="settings.min_call_time"
                    />
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="integration" label="Интеграции">
            <div class="row" style="padding-bottom: 15px">
              <div class="col-12">
                <q-btn
                  style="margin-bottom: 5px;float: right"
                  color="blue"
                  @click="setIntegration()"
                  >Сохранить</q-btn
                >
              </div>
              <h6>СРМ</h6>
              <div class="col-12" v-for="i in integrations.crms" :key="i.type">
                <div style="padding-top: 15px" class="row">
                  <div class="col-4">
                    <label>{{ i.name }}</label>
                  </div>
                  <div class="col-3" v-if="!i.enable">
                    <q-btn
                      label="Включить"
                      color="blue"
                      @click="setIntegration(i, true, 'crm')"
                      size="sm"
                    />
                  </div>
                  <div class="col-3" v-else-if="i.enable">
                    <q-btn
                      label="Выключить"
                      color="red"
                      size="sm"
                      @click="setIntegration(i, false, 'crm')"
                    />
                  </div>
                </div>
                <hr />
              </div>
              <h6>Телефония</h6>
              <div class="col-12" v-for="i in integrations.tels" :key="i.type">
                <div style="padding-top: 15px" class="row">
                  <div class="col-4">
                    <label>{{ i.name }}</label>
                  </div>
                  <div class="col-3" v-if="!i.enable">
                    <q-btn
                      label="Включить"
                      color="blue"
                      @click="setIntegration(i, true, 'tels')"
                      size="sm"
                    />
                  </div>
                  <div class="col-3" v-else-if="i.enable">
                    <q-btn
                      label="Выключить"
                      color="red"
                      size="sm"
                      @click="setIntegration(i, false, 'tels')"
                    />
                  </div>
                </div>
                <hr />
              </div>
              <h6>Облачные хранилища</h6>
              <div class="col-12" v-for="i in integrations.dirs" :key="i.type">
                <div
                  style="padding-top: 15px"
                  class="row"
                  v-if="i.type == 'yad'"
                >
                  <div class="col-4">
                    <label>{{ i.name }}</label>
                  </div>
                  <div class="col-4" v-if="!i.enable">
                    <b>Для подключения диска, свяжитесь с наши менеджером</b>
                  </div>
                  <div class="col-4" v-else-if="i.enable">
                    <q-btn
                      label="Выключить"
                      color="red"
                      size="sm"
                      @click="setIntegration(i, false, 'disk')"
                    />
                  </div>
                </div>
                <div style="padding-top: 15px" class="row" v-else>
                  <div class="col-4">
                    <label>{{ i.name }}</label>
                  </div>
                  <div class="col-3" v-if="!i.enable">
                    <q-btn
                      label="Включить"
                      color="blue"
                      @click="setIntegration(i, true, 'tels')"
                      size="sm"
                    />
                  </div>
                  <div class="col-3" v-else-if="i.enable">
                    <q-btn
                      label="Выключить"
                      color="red"
                      size="sm"
                      @click="setIntegration(i, false, 'tels')"
                    />
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </q-page>
</template>
<script>
import { Notify } from "quasar";

export default {
  name: "gp-setting",
  components: {},
  data() {
    return {
      tabs: [
        { name: "Настройки", id: "setting" },
        { name: "Интеграции", id: "integration" }
      ],
      tab: "setting",
      settings: {},
      integrations: {
        crms: [],
        tels: [],
        dirs: []
      },
      time_zones: [],
      work_time: []
    };
  },
  watch: {
    tab(val) {
      switch (val) {
        case "setting":
          this.getSettings();
          break;
        case "integration":
          this.getIntegration();
          break;
        default:
          this.getSettings();
          break;
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: val
        }
      });
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
    for (let i = -24; i <= 24; i++) {
      if (i > 0) {
        this.time_zones.push("+" + (i < 10 ? "0" + i : i) + ":00");
        this.time_zones.push("+" + (i < 10 ? "0" + i : i) + ":30");
      }
    }
    for (let h = 0; h <= 23; h++) {
      for (let m = 0; m <= 50; m += 10) {
        this.work_time.push(h + ":" + (m == 0 ? "00" : m));
      }
    }
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.$store.state.progress = true;
      this.$h.get("/groups/settings").then(r => {
        this.settings = r.data.data;
        this.$store.state.progress = false;
      });
    },
    setSettings() {
      this.$store.state.progress = true;
      this.$h.post("/groups/settings", { settings: this.settings }).then(r => {
        this.$store.state.progress = false;
        location.reload();
      });
    },
    getIntegration() {
      this.$store.state.progress = true;
      this.$h.get("/groups/integration").then(r => {
        this.integrations = r.data.data;
        this.$store.state.progress = false;
      });
    },
    setIntegration(i, enable, type) {
      i.enable = enable;
      this.$store.state.progress = true;
      this.$h.post("/groups/integration", { int: i, t: type }).then(r => {
        this.integrations = r.data.data;
        location.reload();
      });
    }
  }
};
</script>
<style scoped></style>
