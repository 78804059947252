<template>
  <v-chart
    style="text-align: left"
    :option="option"
    class="myChart"
    :autoresize="true"
    @legendselectchanged="handleClick"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";
import { BarChart, PieChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent
} from "echarts/components";
import { ToolboxComponent } from "echarts/components";
import VChart from "vue-echarts";

use([
  GridComponent,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);
let ch = window.document.getElementsByClassName("myChart");

/*
.on('legendselectchanged', function (params) {
  // obtain selecting status of clicked legend
  var isSelected = params.selected[params.name];
  // print in console
  console.log((isSelected ? 'select' : 'unselect') + 'legend' + params.name);
  // print all legend status
  console.log(params.selected);
});

   */
export default {
  name: "BarChart",
  components: {
    VChart
  },
  props: {
    chOptions: {
      type: Object
    }
  },
  data() {
    return {
      option: null
    };
  },
  mounted() {
    VChart.autoresize = true;
    this.option = {
      id: "barChar",
      color: this.chOptions.color || this.$colors,
      dataZoom: this.chOptions.dataZoom || null,
      tooltip: {
        trigger: "axis",
        axisLabel: {
          formatter: "{value} М"
        }
      },
      title: this.chOptions.title,
      legend: this.chOptions.legend,
      grid: this.chOptions.grid || {},
      yAxis: this.chOptions.yAxis,
      xAxis: this.chOptions.xAxis,
      series: this.chOptions.series
    };
  },
  methods: {
    handleClick(args) {
      this.$emit("legendClick", args);
      //this.option.legend.selected: args.selected
    }
  }
};
</script>

<style scoped></style>

<style></style>
