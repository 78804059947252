<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 15px">
        <div class="bvm_border rev_graf">
          <div class="row">
            <div class="col-12">
              <div class="page_faq" style="padding: 25px">
                <div
                  class="row"
                  style="padding: 15px 0px;border-right: 1px solid;"
                  v-for="(rm, ind) in managers"
                  :key="'r' + rm.user_id + ind"
                >
                  <div class="col-2">
                    <q-avatar
                      :color="rm.color"
                      size="75px"
                      v-if="rm.user_photo"
                    >
                      <img :src="$h.defaults.baseURL + rm.user_photo" />
                    </q-avatar>
                    <q-avatar :color="rm.color" size="75px" v-else>
                      <b style="font-size: 12px">{{
                        rm.first_name.substr(0, 2)
                      }}</b></q-avatar
                    >
                  </div>
                  <div class="col-3 analyt-items">
                    <div
                      :class="'text-' + rm.color"
                    >
                      {{ rm.first_name }}
                    </div>
                  </div>
                  <div class="col-3" style="display: flex;align-items: center">
                    <q-select
                      v-model="rm.call_managers"
                      multiple
                      outlined
                      dense
                      options-dense
                      display-value="Привязанные менеджеры по звонкам"
                      emit-value
                      map-options
                      :options="coll_managers"
                      option-value="id"
                      options-cover
                    >
                      <template
                        v-slot:option="{
                          itemProps,
                          itemEvents,
                          opt,
                          selected,
                          toggleOption
                        }"
                      >
                        <q-item
                          v-bind="itemProps"
                          v-on="itemEvents"
                          style="width: max-content"
                        >
                          <q-item-section>
                            <q-item-label v-html="opt.manager"></q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-toggle
                              :value="selected"
                              @input="toggleOption(opt)"
                            />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                  <div class="col-1"></div>
                  <div class="col-3" style="display: flex;align-items: center">
                    <q-btn
                      style="margin-bottom: 5px;height: 35px"
                      color="blue"
                      @click="setManager(rm.call_managers, rm.user_id)"
                      >Сохранить</q-btn
                    ><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "callmanagers",
  components: {
    //UserInfo,
    // Messages
  },
  data() {
    return {
      managers: [],
      coll_managers: []
    };
  },
  mounted() {
    this.getMans();
  },
  methods: {
    getMans() {
      this.$store.state.progress = true;
      this.$h.get("/analitics/get-managers").then(r => {
        this.managers = r.data.data.m;
        this.coll_managers = r.data.data.cm;
        for(let i in this.managers){
          if(this.managers[i].color =='#000000'){
            this.managers[i].color = this.$colors[i];
           this.$h.post("/analitics/set-man-color" , {m:this.managers[i]}).then(r => {

           })
          }
        }
        this.$store.state.progress = false;
      });
    },
    setManager(mans, id) {
      this.$store.state.progress = true;
      this.$h
        .post("/analitics/set-managers", { mans: mans, usid: id })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    }
  }
};
</script>

<style scoped>
.inv_text_but {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 15px;
  gap: 10px;
  margin: 15px 0;
  height: 39px;
  float: right;
  background: #cecece;
  border-radius: 10px;
  color: white;
}

.inv_text_but_atach {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
  height: 39px;
  float: left;
  background: #cecece;
  border-radius: 10px;
  color: white;
}

.inv_text_but_atach i {
  font-size: 22px;
  padding: 15px;
}

.inv_text_but:hover,
.inv_text_but_atach:hover {
  background: #3a78d3;
  color: white;
}
.analyt-items {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  /* justify-content: center; */
  vertical-align: middle;
  flex-wrap: wrap;
  align-content: center;
}
</style>
