var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"ub-page"},[_c('div',{staticClass:"q-gutter-y-md",staticStyle:{"max-width":"100%"}},[_c('q-card',{staticStyle:{"padding":"15px"}},[_c('div',{staticClass:"bvm_border rev_graf"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page_faq",staticStyle:{"padding":"25px"}},_vm._l((_vm.managers),function(rm,ind){return _c('div',{key:'r' + rm.user_id + ind,staticClass:"row",staticStyle:{"padding":"15px 0px","border-right":"1px solid"}},[_c('div',{staticClass:"col-2"},[(rm.user_photo)?_c('q-avatar',{attrs:{"color":rm.color,"size":"75px"}},[_c('img',{attrs:{"src":_vm.$h.defaults.baseURL + rm.user_photo}})]):_c('q-avatar',{attrs:{"color":rm.color,"size":"75px"}},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(rm.first_name.substr(0, 2)))])])],1),_c('div',{staticClass:"col-3 analyt-items"},[_c('div',{class:'text-' + rm.color},[_vm._v(" "+_vm._s(rm.first_name)+" ")])]),_c('div',{staticClass:"col-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('q-select',{attrs:{"multiple":"","outlined":"","dense":"","options-dense":"","display-value":"Привязанные менеджеры по звонкам","emit-value":"","map-options":"","options":_vm.coll_managers,"option-value":"id","options-cover":""},scopedSlots:_vm._u([{key:"option",fn:function({
                        itemProps,
                        itemEvents,
                        opt,
                        selected,
                        toggleOption
                      }){return [_c('q-item',_vm._g(_vm._b({staticStyle:{"width":"max-content"}},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.manager)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}],null,true),model:{value:(rm.call_managers),callback:function ($$v) {_vm.$set(rm, "call_managers", $$v)},expression:"rm.call_managers"}})],1),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('q-btn',{staticStyle:{"margin-bottom":"5px","height":"35px"},attrs:{"color":"blue"},on:{"click":function($event){return _vm.setManager(rm.call_managers, rm.user_id)}}},[_vm._v("Сохранить")]),_c('br')],1)])}),0)])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }