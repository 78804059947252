<template>
  <q-page class="ub-page">
    <q-card style="padding: 5px;height: 100%">
      <div class="row" v-if="stages.length">
        <div class="col-2">
          <q-select
            filled
            size="xs"
            v-model="stage"
            option-value="id"
            option-label="name"
            :options="stages"
            label="Категория"
          />
        </div>
        <div class="col-10">
          <q-tabs v-model="status" v-if="stage !== null">
            <q-tab
              v-for="r in stage.status"
              :name="r.stage_id + '_' + r.pipeline_id"
              :label="r.name"
              :style="'background-color:' + r.color"
              :key="r.stage_id + Math.random()"
              dense
              ><q-badge :label="r.cfile" color="red" transparent
            /></q-tab>
          </q-tabs>
        </div>
      </div>
    </q-card>
    <div class="bvm_border">
      <b
        style="padding-right: 5px"
        v-for="t in tags"
        :key="t.id + Math.random()"
      >
        <q-checkbox
          v-model="t.use"
          :label="`#${t.name}`"
          checked-icon="star"
          unchecked-icon="star_border"
        />
      </b>
    </div>
    <div class="bvm_border rev_graf">
      <div class="row">
        <div class="col-4">
          <div class="page_faq">
            <label class="reit_name">Рейтинг</label>
            <div
              class="row"
              style="padding: 15px 0px;border-right: 1px solid; cursor: pointer"
              v-for="(rm, ind) in rateManagers"
              :key="'r' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/amo-crm/info/calls/${rm.user_id}`
                )
              "
            >
              <div class="col-3">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-4 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-3 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.progress + dayTitle(parseInt(rm.progress)) }}
                </div>
                <!--
                <q-linear-progress
                  size="20px"
                  :value="rm.progress"
                  :color="rm.color"
                  style="border-radius: 25px"
                >
                  <div
                    class="absolute-full flex flex-center"
                    style="border-radius: 25px"
                  >
                    <q-badge
                      color="white"
                      :text-color="rm.color"
                      :label="(rm.progress * 100).toFixed(0) + '%'"
                      style="border-radius: 25px"
                    />
                  </div>
                </q-linear-progress>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="page_faq">
            <label class="reit_name">Конверсия</label>
            <div
              class="row"
              style="padding: 15px 0px;border-right: 1px solid; cursor: pointer"
              v-for="(rm, ind) in convManagers"
              :key="'c' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/amo-crm/info/calls/${rm.user_id}`
                )
              "
            >
              <div class="col-2">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-2 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-2 analyt-items">{{ rm.f }}</div>
              <div class="col-4 analyt-items">
                <div
                  :style="
                    'text-align: center;width: 100%; color:' + rm.color
                  "
                ></div>
                <q-linear-progress
                  size="20px"
                  :value="rm.progress"
                  :color="rm.color"
                  style="border-radius: 25px"
                >
                  <div
                    class="absolute-full flex flex-center"
                    style="border-radius: 25px"
                  >
                    <q-badge
                      color="white"
                      :text-color="rm.color"
                      :label="(rm.progress * 100).toFixed(0) + '%'"
                      style="border-radius: 25px"
                    />
                  </div>
                </q-linear-progress>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="page_faq">
            <label class="reit_name">Пройденно тестов</label>
            <div
              class="row"
              style="padding: 15px 0px; cursor: pointer"
              v-for="(rm, ind) in trainManagers"
              :key="'t' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/amo-crm/info/tests/${rm.user_id}`
                )
              "
            >
              <div class="col-3">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-5 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-3 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.trains.answ + "/" + rm.trains.all }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="col-3">
          <div class="page_faq" style="min-height: 100%;display: grid">
            <div style="background-color: #a9d8ea" class="solvet_block">
              <div><label>Со временем работы с нашей компанией</label></div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <div class="bvm_border rev_graf">
      <div class="row">
        <div class="col-6">
          <div class="page_faq">
            <label class="reit_name">Колл-во звонков с 10 из 10 баллов</label>
            <div
              class="row"
              style="padding: 15px 0px;border-bottom: 1px solid; cursor: pointer"
              v-for="(rm, ind) in champManagers"
              :key="'r' + rm.user_id + ind"
              @click="
                $router.push(
                  `/managers/amo-crm/info/calls/${rm.user_id}`
                )
              "
            >
              <div class="col-2">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-4 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-5 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.progress + callTitle(parseInt(rm.progress)) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="page_faq">
            <label class="reit_name">Рекомендации</label>
            <div
              class="row"
              style="padding: 15px 0;border-bottom: 1px solid; cursor: pointer"
              v-for="(rm, ind) in recsManagers"
              :key="'r' + rm.user_id + ind"
            >
              <div class="col-2">
                <q-avatar
                  :color="rm.color"
                  size="35px"
                  v-if="rm.user_photo"
                >
                  <img
                    :alt="rm.first_name"
                    :src="$h.defaults.baseURL + rm.user_photo"
                  />
                </q-avatar>
                <q-avatar :color="rm.color" size="35px" v-else>
                  <b style="font-size: 12px">{{
                    rm.first_name.substr(0, 2)
                  }}</b></q-avatar
                >
              </div>
              <div class="col-4 analyt-items">
                <div :class="'text-' + rm.color" style="cursor: pointer">
                  {{
                    rm.first_name.length > 8
                      ? rm.first_name.slice(0, 6) + "..."
                      : rm.first_name
                  }}
                </div>
              </div>
              <div class="col-5 analyt-items">
                <div :class="'text-' + rm.color">
                  {{ rm.recs }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="col-3">
          <div class="page_faq" style="min-height: 100%;display: grid">
            <div style="background-color: #a9d8ea" class="solvet_block">
              <div><label>Идеальный кандидат</label></div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "amo",
  data() {
    return {
      tags: [],
      stags: [],
      stages: [],
      stage: {},
      status: null,
      setMonth: 0,
      rateManagers: [],
      convManagers: [],
      trainManagers: [],
      champManagers: [],
      recsManagers: [],
      loc: "ru",
      index: 0
    };
  },
  computed: {},
  created() {
    this.setMonth = this.$store.state.setMonth;
  },
  mounted() {
    this.getTags();
    this.init();
  },
  watch: {
    "$store.state.setMonth"(val) {
      this.setMonth = val;
      this.init();
    },
    stags() {
      this.getRateMans();
      this.getConvMans();
      this.getTrainingMans();
      this.getChampMans();
      this.getRecsMans();
    },
    stage(val) {
      this.status = val.status[0].stage_id + "_" + val.status[0].pipeline_id;
    },
    status() {
      this.getRateMans();
      this.getConvMans();
      this.getTrainingMans();
      this.getChampMans();
      this.getRecsMans();
      this.$store.state.progress = false;
    },
    index(val) {
      if (val == 0) {
        this.$store.state.progress = false;
      } else {
        this.$store.state.progress = true;
      }
    }
  },
  methods: {
    init() {
      this.getCats();
    },
    getTags() {
      this.index++;
      this.$h.get(`/analitics/amo-crm/get-tags`).then(r => {
        this.tags = r.data.data;
        for (let f in this.tags) {
          console.log(f);
          this.$watch(`tags.${f}.use`, v => {
            this.setTag(f);
          });
        }
        this.index--;
      });
    },
    setTag(t) {
      let val = this.tags[t];
      if (val.use == true) {
        this.stags.push(val.id);
      } else {
        this.stags.splice(this.stags.indexOf(val.id), 1);
      }
    },
    getRateMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/amo-crm/get-man-rating?m=${this.setMonth}&s=${this.status}&y=null&tags=${this.stags.join(",")}`
        )
        .then(r => {
          this.rateManagers = r.data.data;
          this.index--;
        });
    },
    getChampMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/amo-crm/get-man-champ?m=${this.setMonth}&s=${this.status}&y=null&tags=${this.stags.join(",")}`
        )
        .then(r => {
          this.champManagers = r.data.data;
          this.index--;
        });
    },
    getRecsMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/amo-crm/get-man-recs?m=${this.setMonth}&s=${this.status}&y=null&tags=${this.stags.join(",")}`
        )
        .then(r => {
          this.recsManagers = r.data.data;
          this.index--;
        });
    },
    getTrainingMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/amo-crm/get-man-training?m=${this.setMonth}&s=${this.status}&y=null&tags=${this.stags.join(",")}`
        )
        .then(r => {
          this.trainManagers = r.data.data;
          this.index--;
        });
    },
    getConvMans() {
      this.index++;
      this.$h
        .get(
          `/analitics/amo-crm/get-man-conv?m=${this.setMonth}&s=${this.status}&y=null&tags=${this.stags.join(",")}`
        )
        .then(r => {
          this.convManagers = r.data.data;
          this.index--;
        });
    },
    getCats() {
      this.$store.state.progress = true;
      this.$h
        .get(`/analitics/amo-crm/stages?m=${this.setMonth}`)
        .then(r => {
          console.log(r.data.data);
          this.stages = r.data.data.stages;
          if (this.stages.length > 0) {
            this.stage = this.stages[0];
            this.status =
              this.stage.status[0].stage_id +
              "_" +
              this.stage.status[0].pipeline_id;
          }
          this.$store.state.progress = false;
        })
        .catch(() => {
          this.$store.state.progress = false;
        });
    },
    dayTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " баллов";
      let last_num = number % 10;
      if (Math.abs(last_num) == 1) return " балл";
      if ([2, 3, 4].includes(Math.abs(last_num))) return " балла";
      if ([5, 6, 7, 8, 9, 0].includes(Math.abs(last_num))) return " баллов";
    },
    callTitle(number) {
      if (number > 10 && [11, 12, 13, 14].includes(number % 100))
        return " звонков";
      let last_num = number % 10;
      if (Math.abs(last_num) == 1) return " звонок";
      if ([2, 3, 4].includes(Math.abs(last_num))) return " звонка";
      if ([5, 6, 7, 8, 9, 0].includes(Math.abs(last_num))) return " звонков";
    }
  }
};
</script>

<style scoped>
.reit_name {
  color: #2d5ea9;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0;
}
.page_faq {
  padding: 30px;
}
.page_faq b,
.page_faq p {
  font-size: 16px;
}
.q-linear-progress__model,
.q-linear-progress__track {
  border-radius: 25px !important;
}

.analyt-items {
  display: flex;
  /* justify-content: center; */
  align-content: center;
  flex-wrap: wrap;
}
.solvet_block {
  color: #2d5ea9;
  padding: 15px;
  height: 100%;
  border-radius: 25px;
  background-color: rgb(169, 216, 234);
}
.solvet_block label {
  font-weight: 600;
}
.page_faq .row:hover {
  background-color: #fff9ea;
  cursor: pointer;
}
</style>
