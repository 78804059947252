<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <div class="q-pa-lg">
          <div class="col-6" style="display: flex">
            <input
              id="searchComp"
              :disabled="$store.state.progress"
              v-model="searchNum"
              style="width: 300px;text-align: center;margin: auto"
            />
          </div>
          <div class="q-pa-lg flex flex-center">
            <q-pagination v-model="current" :max="maxp" direction-links />
            <select v-model="limit">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">250</option>
              <option value="1000">1000</option>
              <option value="1500">1500</option>
              <option value="2500">2500</option>
              <option value="5000">5000</option>
            </select>
          </div>
          <div class="row">
            <div class="col-2 text-center">
              Группа в тг
            </div>
            <div class="col-6 text-center"></div>
            <div class="col-1 text-center">Поиск эталона</div>
            <div class="col-1 text-center">Статус</div>
            <div class="col-2"></div>
          </div>
          <div
            v-for="c in groups"
            :key="c.id"
            class="row"
            style="padding: 10px 5px"
          >
            <div class="col-12">
              <div class="row" style="padding: 10px 5px">
                <div class="col-2">
                  <b>{{ c.chat_name }}</b
                  ><br />
                  <q-btn
                    color="green"
                    @click="
                      $router.push(`/settings/group-info?gid=${c.id_group}`)
                    "
                    >Подробнее</q-btn
                  >
                  <q-btn
                    style="float: right;margin-right: 15px"
                    label="Пополнить"
                    color="blue"
                    @click="setMoney(c)"
                  />
                  <br />
                  <div style="padding-top: 25px" class="row">
                    <div class="col-4">
                      <label>Пояс GMT</label><br />
                      <select v-model="c.time_zone">
                        <option
                          v-for="tz in time_zones"
                          :key="tz + c.chat_name"
                          >{{ tz }}</option
                        >
                      </select>
                    </div>
                    <div class="col-8">
                      <label>Время уведомлений</label><br />
                      <select v-model="c.end_work_time">
                        <option
                          v-for="wt in work_time"
                          :key="wt + wt.chat_name"
                          >{{ wt }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div>Асистент</div>
                  <select
                    v-model="c.id_assist"
                    style="float: left;margin-right: 15px"
                  >
                    <option value="null">Отвязать</option>
                    <option
                      v-for="a in assists"
                      :key="a.id + Math.random()"
                      :value="a.id"
                      >{{ a.name }}</option
                    >
                  </select>
                </div>
                <div class="col-3">
                  <label
                    >Шаблон
                    <select
                      v-model="c.temp_id"
                      style="float: right;margin-right: 15px;width: 250px"
                    >
                      <option value="0">Без шаблона</option>
                      <option
                        v-for="t in templates"
                        :key="t.id + Math.random()"
                        :value="t.id"
                        >{{ t.name }}</option
                      >
                    </select>
                  </label>
                  <input
                    style="margin-top: 5px;float: left;color: red"
                    type="button"
                    @click="saveTemplate(c)"
                    value="Сохранить как новый"
                  />
                  <input
                    style="margin-top: 5px;float: right;color: green"
                    type="button"
                    @click="setTemplate(c)"
                    value="Применить шаблон"
                  />
                </div>
                <div class="col-1" style="text-align: center">
                  <q-checkbox
                    color="blue"
                    :label="c.use_etalon ? 'ЧекЛист' : 'Эталон'"
                    v-model="c.use_etalon"
                  />
                </div>
                <div class="col-1" style="text-align: center">
                  <q-checkbox
                    color="blue"
                    :label="c.enable ? 'ВКЛ' : 'ВЫКЛ'"
                    v-model="c.enable"
                  />
                </div>
                <div class="col-2" style="text-align: center">
                  <q-btn
                    style="margin-bottom: 5px"
                    color="blue"
                    @click="setGroups(c)"
                    >Сохранить</q-btn
                  >
                  <br />
                  <q-btn color="red" @click="leaveGroups(c)"
                    >Выйти из группы</q-btn
                  >
                </div>
              </div>
            </div>
            <hr style="border: 1px solid black;width: 100%" />
          </div>
        </div>
      </q-card>
      <q-dialog
        v-model="delConfirm"
        v-if="delAlg"
        persistent
        style="min-width: 300px"
      >
        <q-card>
          <q-card-section class="row items-center">
            <span style="text-align: center"
              >Уверены, что хотите выйти из <br /><b>{{
                delAlg.chat_name
              }}</b></span
            >
          </q-card-section>

          <q-card-actions align="center">
            <q-btn
              flat
              label="Отмена"
              @click="delConfirm = false"
              color="primary"
              v-close-popup
            />
            <q-btn
              flat
              label="Выйти"
              @click="deleteAlg"
              color="red"
              v-close-popup
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-dialog v-model="dsetmoney" persistent>
        <q-card style="min-width: 150px">
          <q-card-section>
            <div class="text-h6">Пополнить баланс {{ ggroup }}</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-input dense v-model="gmoney" type="number" autofocus />
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn
              label="Отмена"
              color="red"
              v-close-popup
              style="margin-right: 25px"
            />
            <q-btn label="Пополнить" @click="sendMoney()" color="blue" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "settings",
  components: {},
  data() {
    return {
      dsetmoney: false,
      ggroup: "",
      gmoney: 0.0,
      ggroupid: 0,
      searchNum: "",
      current: 1,
      maxp: 0,
      limit: 20,
      delConfirm: false,
      delAlg: null,
      groups: [],
      assists: [],
      vectors: [],
      templates: [],
      time_zones: [],
      work_time: []
    };
  },
  created() {
    for (let i = -24; i <= 24; i++) {
      if (i > 0) {
        this.time_zones.push("+" + (i < 10 ? "0" + i : i) + ":00");
        this.time_zones.push("+" + (i < 10 ? "0" + i : i) + ":30");
      }
    }
    for (let h = 0; h <= 23; h++) {
      for (let m = 0; m <= 50; m += 10) {
        this.work_time.push(h + ":" + (m == 0 ? "00" : m));
      }
    }

    if (this.$route.query.limit) {
      this.limit = parseInt(this.$route.query.limit);
    }

    if (this.$route.query.search) {
      this.searchNum = this.$route.query.search;
    }
    if (this.$route.query.current) {
      this.current = parseInt(this.$route.query.current) || 1;
    }
  },
  mounted() {
    this.getGroups();
  },
  watch: {
    dsetmoney(v) {
      if (v == false) {
        this.ggroup = "";
        this.gmoney = 0.0;
        this.ggroupid = 0;
      }
    },
    searchNum(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          current: this.current,
          search: val,
          limit: this.limit
        }
      });
      if (val.toString().length > 2 || val.toString().length == 0) {
        this.getGroups();
      }
    },

    current(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          current: val || 1,
          search: this.searchNum,
          limit: this.limit
        }
      });

      this.getGroups();
    },
    limit(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          current: this.current,
          search: this.searchNum,
          limit: val
        }
      });
      this.getGroups();
    }
  },
  methods: {
    setMoney(g) {
      this.ggroup = g.chat_name;
      this.ggroupid = g.id_group;
      this.dsetmoney = true;
    },
    sendMoney() {
      this.$store.state.progress = true;
      this.$h
        .post("/billing/set-money", { g: this.ggroupid, m: this.gmoney })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: r.data.message,
              timeout: 2000
            });
          }
          this.dsetmoney = false;
          this.$store.state.progress = false;
        });
    },
    deleteAlg() {
      this.$store.state.progress = true;
      this.$h.get(`/settings/leave-chat?gbId=${this.delAlg.id}`).then(r => {
        this.$store.state.progress = false;
        location.reload();
      });
    },
    saveTemplate(g) {
      this.$store.state.progress = true;
      this.$h.post("/settings/set-new-temp", { g: g.id_group }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Ошибка",
            timeout: 2000
          });
        } else {
          g.temp_id = r.data.data.id;
          this.templates = r.data.data.t;
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
        }
        this.$store.state.progress = false;
      });
    },
    setTemplate(g) {
      this.$store.state.progress = true;
      this.$h
        .post("/settings/set-group-temp", { g: g.id_group, t: g.temp_id })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            g.temp_id = r.data.data.id;
            this.templates = r.data.data.t;
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    },
    getGroups() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/settings/get-bot-groups?limit=${this.limit}&offset=${(this.current -
            1) *
            this.limit}&search=${this.searchNum}`
        )
        .then(r => {
          this.groups = r.data.data.g;
          this.vectors = r.data.data.v;
          this.assists = r.data.data.a;
          this.templates = r.data.data.t;
          const m = parseInt(r.data.data.m);
          this.maxp = r.data.data.m >= m ? m + 1 : m;
          this.$store.state.progress = false;
          if (this.searchNum.length > 2) {
            setTimeout(function() {
              if (document.getElementById("searchComp")) {
                document.getElementById("searchComp").focus();
              }
            }, 0);
          }
        });
    },
    leaveGroups(group) {
      this.delAlg = group;
      this.delConfirm = true;
    },
    setGroups(group) {
      for (let i = 0; i < this.assists.length; i++) {
        if (this.assists[i].id == group.id_assist) {
          group.assist_name = this.assists[i].name;
        }
      }
      this.$store.state.progress = true;
      this.$h.post("/settings/set-groups", { g: group }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Ошибка",
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
        }
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.comp_number {
  display: flex;
  width: 100%;
  min-height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
</style>
