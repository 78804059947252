import Vue from "vue";
import Router from "vue-router";
import MainLayout from "./layouts/mainlayout.vue";
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
import Login from "@/pages/login.vue";

import Error_p from "@/pages/error_p";

import Main from "@/pages/main.vue";
import Settings from "@/pages/settings.vue";
import Users from "@/pages/users.vue";
import Analytics from "@/pages/analytics.vue";
import Callmanagers from "@/pages/callmanagers.vue";
import Group_info from "@/components/setting/group_info.vue";
import Templates from "@/pages/templates.vue";
import ManagerInfo from "@/components/managers/manager-info.vue";
import GpSetting from "@/components/setting/gp-setting.vue";
import Crm from "@/pages/crm.vue";
import Billing from "@/pages/billing.vue";
import Teltags from "@/components/telefony/teltags.vue";
import Disk from "@/pages/disk.vue";
import Telefony from "@/pages/telefony.vue";
import ManagerCalls from "@/components/managers/manager-calls.vue";
import ManagerTests from "@/components/managers/manager-tests.vue";
import Amocrm from "@/components/crm-setting/amocrm.vue";
import Bit24 from "@/components/crm-setting/bit24.vue";
import Domru from "@/components/telefony/domru.vue";
import Telfin from "@/components/telefony/telfin.vue";
import Yad from "@/components/disk/yad.vue";
import Billing_groups from "@/pages/billing_groups.vue";
import Managers from "@/pages/managers.vue";
import Yadtags from "@/components/disk/yadtags.vue";
import Crmtags from "@/components/crm-setting/crmtags.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: MainLayout,
      children: [
        {
          path: "/",
          name: "main_page",
          component: Main
        },
        {
          path: "/settings",
          name: "settings",
          component: Settings
        },
        {
          path: "/settings/group-info",
          name: "group-info",
          component: Group_info
        },
        {
          path: "/users",
          name: "users",
          component: Users
        },
        {
          path: "/analytics/:crm",
          name: "analytics",
          component: Analytics
        },
        {
          path: "/managers/:crm/info/:type/:id",
          name: "manager-info",
          component: Managers
        },
        {
          path: "/analytics/manager-calls",
          name: "manager-calls",
          component: ManagerCalls
        },
        {
          path: "/analytics/manager-tests",
          name: "manager-tests",
          component: ManagerTests
        },
        {
          path: "/call-managers",
          name: "callmanagers",
          component: Callmanagers
        },
        {
          path: "/templates",
          name: "templates",
          component: Templates
        },
        {
          path: "/group-setting",
          name: "gp-setting",
          component: GpSetting
        },
        {
          path: "/crm",
          name: "crm",
          component: Crm,
          children: [
            {
              path: "amo_crm",
              name: "amo_crm",
              component: Amocrm
            },
            {
              path: "bit24_crm",
              name: "bit24_crm",
              component: Bit24
            },
            {
              path: "tags",
              name: "crm_tags",
              component: Crmtags
            }
          ]
        },
        {
          path: "/telefony",
          name: "telefony",
          component: Telefony,
          children: [
            {
              path: "domru",
              name: "domru",
              component: Domru
            },
            {
              path: "telfin",
              name: "telfin",
              component: Telfin
            } ,
            {
              path: "tags",
              name: "tel_tags",
              component: Teltags
            },
          ]
        },
        {
          path: "/disk",
          name: "disk",
          component: Disk,
          children: [
            {
              path: "yad",
              name: "yad",
              component: Yad
            },
            {
              path: "tags",
              name: "disk_tags",
              component: Yadtags
            },
          ]
        },
        {
          path: "/billing",
          name: "billing",
          component: Billing
        },
        {
          path: "/billing-groups",
          name: "billing_groups",
          component: Billing_groups
        },

        {
          path: "/logout",
          name: "logout"
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      component: Login
    },
    {
      path: "*",
      name: "error",
      component: Error_p
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some(rec => rec.meta.is_admin)) {
        if (user.role == "admin") {
          next();
        } else {
          next({ name: "showcase" });
        }
      } else {
        next();
      }
      if (to.matched.some(recs => recs.meta.level)) {
        if (user.level >= to.meta.level) {
          next();
        } else {
          next({ name: "showcase" });
        }
      }
    }
  } else {
    next();
  }
});

export default router;
